export const userAccessToScreen = {
	home_finalise: ['SO', 'SH'],
	uploads: ['AP', 'ASP', 'MS', 'SM'],
	billingsummary: ['FA', 'SO', 'AP', 'ASP', 'NSOM', 'NSM', 'PA', 'PAT', 'ASI', 'SH', 'RSM'],
	incentives: ['NSOM', 'NSM'],
	penalty: ['PA', 'PAT',],
	acc_bonus_upload: ['MS', 'SM'],
	billing_RSM_View: ['FA', 'SO', 'NSOM', 'NSM', 'SH', 'PA', 'PAT', 'AP'],
	billing_ASI_View: ['FA', 'SO', 'NSOM', 'NSM', 'SH', 'PA', 'PAT', 'AP', 'RSM'],
	billing_ASP_View: ['FA', 'SO', 'NSOM', 'NSM', 'SH', 'PA', 'PAT', 'AP', 'RSM', 'ASI'],
	super_user: ['ASP', 'ASI', 'RSM']
}

export const menuItems = {
	ALL: [
		{ name: 'Home', path: 'home' },
		{ name: 'Uploads', path: 'uploads' },
		{ name: 'Billing Summary', path: 'billingsummary' },
		{ name: 'Incentives', path: 'incentives' },
		{ name: 'Penalty', path: 'penalty' },
	],
	SO: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	],
	AP: [
		{ name: 'Home', path: 'home' },
		{ name: 'Uploads', path: 'uploads' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	],
	ASP: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	],
	MS: [
		{ name: 'Home', path: 'home' },
		{ name: 'Uploads', path: 'acc_bonus_upload' },
	],
	SM: [
		{ name: 'Home', path: 'home' },
		{ name: 'Uploads', path: 'acc_bonus_upload' },
	],
	NSOM: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
		{ name: 'Incentives', path: 'incentives' },
	],
	NSM: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
		{ name: 'Incentives', path: 'incentives' },
	],
	PA: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
		{ name: 'Penalty', path: 'penalty' },
	],
	PAT: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
		{ name: 'Penalty', path: 'penalty' },
	],
	ASI: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	],
	SH: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	],
	RSM: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	],
	FA: [
		{ name: 'Home', path: 'home' },
		{ name: 'Billing Summary', path: 'billingsummary' },
	]

};

export const breadcrumbNameMap = {
	'/home': 'Home',
	'/billingsummary': 'Billing Summary',
	'/billinghistory': 'Billing History',
	'/profile': 'Profile',
	'/incentives': 'Incentives',
	'/penalty': 'Penalty',
	'/uploads': 'Uploads',
	'/acc_bonus_upload': 'Uploads',
	'/approval_pending': 'Approval Pending',
	'/pagenotfound': '404'
};

export const authUrl = `${process.env.REACT_APP_API_BASE_URL}/authenticate`
export const authPassword = "L06Fh1LqubvxpT7"
export const autherizeUrl = `${process.env.REACT_APP_API_BASE_URL}/isAuthorized`
export const redirectToHomeUrl = `${process.env.REACT_APP_BASE_URL}/home`
export const dailyUploadUrl = `${process.env.REACT_APP_API_BASE_URL}/`
export const logoutURL = `${process.env.REACT_APP_API_BASE_URL}/logout`
export const BillingHistory_URL = `${process.env.REACT_APP_API_BASE_URL}/getBillingSummaryHistory`
export const ENCRYPTION_KEY = '1234567812345678'
export const ENCRYPTION_IV = '1234567812345678'
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'Jul', 'August', 'September', 'October', 'November', 'December'];
export const billingSummaryUrl = `/billingSummary`;
export const billingChangeReqUrl = `${process.env.REACT_APP_API_BASE_URL}/billingChangeReq`;
export const uploadIncentiveUrl = `${process.env.REACT_APP_API_BASE_URL}/uploadIncentivePenaltyFile`
export const addIncentivePenaltyUrl = `${process.env.REACT_APP_API_BASE_URL}/addIncentivePenalty`
export const downloadIncentivePenaltyUrl = `${process.env.REACT_APP_API_BASE_URL}/downloadIncentivePenaltyFile`;
export const downloadTravelAllowanceUrl = `${process.env.REACT_APP_API_BASE_URL}/downloadTravelAllowance`;
export const getBillingChangeRequestUrl = `/getBillingChangeRequest`;
export const getASPListURL = `${process.env.REACT_APP_API_BASE_URL}/getServiceProviderDetails`
export const approveBillingChangeRequestUrl = `/approveBillingChangeRequest`;
export const uploadTAUrl = `${process.env.REACT_APP_API_BASE_URL}/uploadTravelAllowance`;
export const uploadABUrl = `${process.env.REACT_APP_API_BASE_URL}/uploadAccessoriesBonus`;
export const finaliseBillingSummaryUrl = `${process.env.REACT_APP_API_BASE_URL}/finaliseBillingSummary`;
export const ENCRYPTION_FLAG = true;
export const isDummyData = false;
export const regExTestFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
export const regExMatchFormat = /^[^a-zA-Z0-9]+$/;
export const fileSize5mb = 5242880;
export const billingSummaryModalData =
	[
		{ name: 'Call Type', key: 'callType', value: null },
		{ name: 'External Reference ID', key: 'extReferenceId', value: null },
		{ name: 'Product Reference ID', key: 'productReferenceId', value: null },
		{ name: 'Product Name', key: 'productName', value: null },
		{ name: 'Product Category', key: 'productCategory', value: null },
		{ name: 'Sub Category', key: 'productSubCategory', value: null },
		{ name: 'O/SS', key: 'oss', value: null },
		{ name: 'Sales Invoice Number', key: 'salesInvoiceNumber', value: null },
		{ name: 'Date of purchase', key: 'dateOfPurchase', value: null },
		{ name: 'Warranty', key: 'warranty', value: null, type: null },
		{ name: 'PinCode', key: 'pincode', value: null, type: null },
		{ name: 'Servify Call Creation DateTime', key: 'servifyCallCreationDateTime', value: null, type: null },
		{ name: 'Servify Call Closure DateTime', key: 'servifyCallClosureDateTime', value: null, type: null },
		{ name: 'TAT', key: 'tat', value: null, type: null },
		{ name: 'Servify Serial number', key: 'servifySerialNumber', value: null },
		{ name: 'Technician', key: 'technician', value: null },
		{ name: 'Service Location', key: 'serviceLocation', value: null },
		{ name: 'L1', key: 'l1', value: null },
		{ name: 'L2', key: 'l1', value: null },
		{ name: 'L3', key: 'l3', value: null },
		{ name: 'Repeats in (days)', key: 'repeatInDays', value: null },
		{ name: 'Is Repeat', key: 'isRepeat', value: null, type: null },
		{ name: 'Job Rate', key: 'jobRate', value: null, type: 'edit' },
		{ name: 'Royalty', key: 'royalty', value: null, type: 'edit' },
		// { name: 'TA', key: 'travelAllowance', type: 'edit' },
		{ name: 'Total', key: 'total', value: null, type: 'edit', colorCode: '#A02C8C' },
	]

export const pendingApprovalModalData = [
	{ name: 'Servify Serial number', key: 'servifySerialNumber', value: null },
	{ name: 'External Reference ID', key: 'extReferenceId', value: null },
	{ name: 'Product Name', key: 'productName', value: null },
	{ name: 'Date of purchase', key: 'dateOfPurchase', value: null },
	{ name: 'Product Category', key: 'productCategory', value: null },
	{ name: 'Sub Category', key: 'subCategory', value: null },
	{ name: 'Solution Provided', key: 'solutionProvided', value: null },
	{ name: 'ID Incentive Payout', key: 'idIncentivePayout', value: null },
	{ name: 'BD Incentive Payout', key: 'bdIncentivePayout', value: null },
	{ name: 'Travel Allowance', key: 'travelAllowance', value: null },
	{ name: 'Repeats in (days)', key: 'repeatInDays', value: null },
	{ name: 'Brand', key: 'brand', value: null, type: null },
	{ name: 'Product ID', key: 'productId', value: null, type: null },
	{ name: 'Call Rate', key: 'callRate', value: null, type: null },
	{ name: 'PinCode', key: 'pincode', value: null, type: null },
	{ name: 'Delivery Type', key: 'deliveryType', value: null, type: null },
	{ name: 'Repeat Call Rate', key: 'repeatCallRate', value: null, type: null },
	{ name: 'TAT', key: 'tat', value: null, type: null },
	{ name: 'Royalty', key: 'royalty', value: null, type: null },
	{ name: 'Warranty', key: 'warranty', value: null, type: null },
	{ name: 'Is Repeat', key: 'isRepeat', value: null, type: null },
	{ name: 'Heading', key: '', value: 'Heading', type: "greyarea_table_values" },
	{ name: '', key: '', value: 'Warranty', type: "greyarea_table_values" },
	{ name: '', key: '', value: 'Is Repeat', type: 'greyarea_table_values' },
	{ name: 'Original', key: '', value: 'Original', type: "greyarea_table_values" },
	{ name: '', key: 'warranty', value: null, type: "greyarea_table_values" },
	{ name: '', key: 'isRepeat', value: null, type: 'greyarea_table_values' },
	{ name: 'Revised', key: '', value: 'Revised', type: "greyarea_table_values" },
	{ name: '', key: 'warranty', value: null, type: "greyarea_table_edit" },
	{ name: '', key: 'isRepeat', value: null, type: 'greyarea_table_edit' },
	{ name: 'Reason for request:', key: 'comments', value: null, type: 'greyarea_text' }
]

export const billingSummaryInfoCardData = [
	{ label: 'Job Rate', value: 'jobRate', colorCode: '#959595', download: false, valueSign: '₹' },
	{ label: 'Royalty', value: 'royalty', colorCode: '#D11F1F', download: false, valueSign: '₹-' },
	// { label: 'TA', value: 'travelAllowance', colorCode: '#959595', download: false, valueSign: '₹' },
	{ label: 'ID CSAT incentive', value: 'idCSATIncentive', colorCode: '#959595', download: false, valueSign: '₹' },
	{ label: 'FS CSAT incentive', value: 'fsCSATIncentive', colorCode: '#959595', download: false, valueSign: '₹' },
	{ label: 'Accs. Bonus', value: 'accessoriesBonus', colorCode: '#959595', download: false, valueSign: '₹' },
	{ label: 'Incentive', value: 'totalIncentive', colorCode: '#249F10', download: false, expandable: true, valueSign: '₹+' },
	{ label: 'Penalty', value: 'totalPenalty', colorCode: '#D11F1F', download: false, expandable: true, valueSign: '₹-' },
	{ label: 'Final Amount:', value: 'finalAmount', colorCode: '#088466', download: false, valueSign: '₹', type: 'total' }
]

export const incentivePenaltySummaryDummayData = [
	{
		"outcome": "Incentive",
		"totalAmount": 0,
		"description": "string",
		"downloadableFiles": [
			{ "fileName": "Incentive01", "amount": 10 },
			{ "fileName": "Incentive02", "amount": 200 },
			{ "fileName": "Incentive03", "amount": 3000 },
		]
	},
	{
		"outcome": "Penalty",
		"totalAmount": 0,
		"description": "string",
		"downloadableFiles": [
			{ "fileName": "Penalty01", "amount": 10.00 },
			{ "fileName": "Penalty02", "amount": 200.00 },
			{ "fileName": "Penalty03", "amount": 3000.00 },
		]
	}
]

export const dummyBillingSummaryData = {
	ASP: {
		"billingSummary": [
			{
				"extReferenceId": "SFC240823ID48820",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC4244738",
				"dateOfPurchase": "2023-08-16T18:30:00.000+00:00",
				"pincode": 361325,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.8,
				"servifyCallClosureDateTime": "0031-02-13T09:40:00.000+00:00",
				"servifySerialNumber": "CREL043USA024601T032303168",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC260823ID97199",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4349762",
				"dateOfPurchase": "2023-08-24T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.63,
				"servifyCallClosureDateTime": "0033-02-12T07:09:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T072300726",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC220823ID70712",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4290016",
				"dateOfPurchase": "2023-08-20T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.89,
				"servifyCallClosureDateTime": "0029-02-12T13:23:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082305975",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210823ID63016",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4274555",
				"dateOfPurchase": "2023-08-19T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 0.63,
				"servifyCallClosureDateTime": "0028-02-13T07:42:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082302777",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210823ID63014",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4274553",
				"dateOfPurchase": "2023-08-19T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 0.81,
				"servifyCallClosureDateTime": "0028-02-13T12:04:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082305888",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID35028",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4216118",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.91,
				"servifyCallClosureDateTime": "0025-02-12T14:15:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082303772",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID35021",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4216117",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.76,
				"servifyCallClosureDateTime": "0025-02-12T10:44:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082306199",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC140823ID80059",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4099597",
				"dateOfPurchase": "2023-08-12T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.7,
				"servifyCallClosureDateTime": "0021-02-12T08:17:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T072300972",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69689",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076612",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0020-02-13T11:05:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T072300705",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69686",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076610",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0020-02-13T11:04:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082304428",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC120823ID62906",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4054551",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 0.92,
				"servifyCallClosureDateTime": "0019-02-13T12:35:00.000+00:00",
				"servifySerialNumber": "CREL032HBC024601T082302680",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC100823ID54030",
				"callType": "ID",
				"productReferenceId": "275419",
				"productName": "Croma LED 80cm CREL032HBC024601 HDR TV",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4014405",
				"dateOfPurchase": "2023-08-08T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-09T18:30:00.000+00:00",
				"tat": 0.12,
				"servifyCallClosureDateTime": "0016-02-13T15:00:00.000+00:00",
				"servifySerialNumber": "CREL032HBC24601T072301186",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID35355",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4216586",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.63,
				"servifyCallClosureDateTime": "0025-02-12T07:36:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300487",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID35351",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4216580",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.6,
				"servifyCallClosureDateTime": "0025-02-12T07:02:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300781",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID21414",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4184451",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.78,
				"servifyCallClosureDateTime": "0024-02-13T10:39:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300426",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID20907",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4184450",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.78,
				"servifyCallClosureDateTime": "0024-02-13T10:44:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300777",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID21101",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4184449",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.93,
				"servifyCallClosureDateTime": "0024-02-13T14:20:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300488",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID20993",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4184447",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.78,
				"servifyCallClosureDateTime": "0024-02-13T10:40:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300478",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID20545",
				"callType": "ID",
				"productReferenceId": "273732",
				"productName": "Croma LED 80cm 32HSC24601 Smart TV HD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4184446",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.78,
				"servifyCallClosureDateTime": "0024-02-13T10:38:00.000+00:00",
				"servifySerialNumber": "CREL032HSC024601T082300477",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC250823ID90213",
				"callType": "ID",
				"productReferenceId": "273730",
				"productName": "CROMA LED 140CM 55UGC024601 GOOGLETV UHD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4334326",
				"dateOfPurchase": "2023-08-23T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 0.62,
				"servifyCallClosureDateTime": "0032-02-13T07:24:00.000+00:00",
				"servifySerialNumber": "CREL055UGC024601T072302113",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID34802",
				"callType": "ID",
				"productReferenceId": "273730",
				"productName": "CROMA LED 140CM 55UGC024601 GOOGLETV UHD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4215627",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.8,
				"servifyCallClosureDateTime": "0025-02-12T11:44:00.000+00:00",
				"servifySerialNumber": "CREL055UGC024601T072303211",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID06892",
				"callType": "ID",
				"productReferenceId": "273359",
				"productName": "Croma LED 127cm 50UGC024601 Google TV UHD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4162001",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.81,
				"servifyCallClosureDateTime": "0023-02-13T11:56:00.000+00:00",
				"servifySerialNumber": "CREL050UGC024601T082301849",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC270823ID01840",
				"callType": "ID",
				"productReferenceId": "273358",
				"productName": "Croma LED 109cm 43UGC024601 GoogleTV UHD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4362028",
				"dateOfPurchase": "2023-08-25T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 0.96,
				"servifyCallClosureDateTime": "0034-02-13T14:33:00.000+00:00",
				"servifySerialNumber": "CREL043UGC024601T082302723",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID35009",
				"callType": "ID",
				"productReferenceId": "273357",
				"productName": "Croma LED 109cm 43FGD024601 GoogleTV FHD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4216085",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.86,
				"servifyCallClosureDateTime": "0025-02-12T13:06:00.000+00:00",
				"servifySerialNumber": "CREL043FGD024601T082308047",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC290823ID21699",
				"callType": "ID",
				"productReferenceId": "273357",
				"productName": "Croma LED 109cm 43FGD024601 GoogleTV FHD",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166353599586",
				"dateOfPurchase": "2023-08-28T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 1.14,
				"servifyCallClosureDateTime": "0036-02-13T12:28:00.000+00:00",
				"servifySerialNumber": "CREL043FGD024601T082307614",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210823ID61637",
				"callType": "ID",
				"productReferenceId": "272808",
				"productName": "Croma W/M TL 8Kg CRLW080FAF276205 Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4272533",
				"dateOfPurchase": "2023-08-19T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 0.75,
				"servifyCallClosureDateTime": "0028-02-13T10:31:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF276205072300104",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC300823ID24363",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4408167",
				"dateOfPurchase": "2023-08-28T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-29T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0037-02-12T15:08:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072312998",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC250823ID88257",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4330336",
				"dateOfPurchase": "2023-08-23T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 0.58,
				"servifyCallClosureDateTime": "0032-02-13T06:22:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072314599",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID39192",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4225387",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.65,
				"servifyCallClosureDateTime": "0025-02-12T08:04:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T062304342",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID23716",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4191341",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.86,
				"servifyCallClosureDateTime": "0024-02-13T12:35:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072313157",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID22592",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4185220",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.65,
				"servifyCallClosureDateTime": "0024-02-13T07:34:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072315090",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID72411",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4081439",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.83,
				"servifyCallClosureDateTime": "0020-02-13T12:35:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072314788",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC070823ID32874",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3955784",
				"dateOfPurchase": "2023-08-03T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 0.64,
				"servifyCallClosureDateTime": "0014-02-13T08:23:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072308858",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID25596",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166353253557",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.19,
				"servifyCallClosureDateTime": "0022-02-13T13:14:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072313070",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC150823ID03198",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166353173514",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-14T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0022-02-13T07:56:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072309745",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC080823ID48346",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166352963085",
				"dateOfPurchase": "2023-08-07T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.88,
				"servifyCallClosureDateTime": "0015-02-13T12:04:00.000+00:00",
				"servifySerialNumber": "CREL033HGC024601T062303444",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC020823ID11874",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166352800241",
				"dateOfPurchase": "2023-08-01T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0009-02-12T14:47:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072310205",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC230823ID84245",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2023-08-22T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-22T18:30:00.000+00:00",
				"tat": 0.64,
				"servifyCallClosureDateTime": "0030-02-13T05:57:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072313150",
				"technician": "Sameer Godhaviya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC150823ID11701",
				"callType": "ID",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-14T18:30:00.000+00:00",
				"tat": 0.56,
				"servifyCallClosureDateTime": "0022-02-13T07:20:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T072313124",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280723RF00142",
				"callType": "RF",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-10-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-27T18:30:00.000+00:00",
				"tat": 4.04,
				"servifyCallClosureDateTime": "0007-02-13T11:07:00.000+00:00",
				"servifySerialNumber": "CREL7369072285146",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280723RF00144",
				"callType": "RF",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-10-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-27T18:30:00.000+00:00",
				"tat": 5.83,
				"servifyCallClosureDateTime": "0009-02-12T06:00:00.000+00:00",
				"servifySerialNumber": "CREL032HOF024601T062364920",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823RF00001",
				"callType": "RF",
				"productReferenceId": "272043",
				"productName": "Croma 80cm HDr LEDTV CREL032HGC024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-10-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 2.25,
				"servifyCallClosureDateTime": "0012-02-13T11:21:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T0623444",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Software Update Done $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD190823FS03822",
				"callType": "FS",
				"productReferenceId": "270043",
				"productName": "Croma Cooktop 3B GT CRLG3GTGSA279207",
				"warranty": "IW",
				"productCategory": "Oven Toaster Grill",
				"productSubCategory": "Gas StovesCooktops",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166351070505",
				"dateOfPurchase": "2023-05-27T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 1.82,
				"servifyCallClosureDateTime": "0027-02-13T10:00:00.000+00:00",
				"servifySerialNumber": "CRLG3GTGSA279207",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823FS02394",
				"callType": "FS",
				"productReferenceId": "268675",
				"productName": "CROMA W/M SEMI 7KG CRLW070SMF248601",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SOA166353499398",
				"dateOfPurchase": "2023-08-24T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 2.03,
				"servifyCallClosureDateTime": "0035-02-13T13:15:00.000+00:00",
				"servifySerialNumber": "CRLW070SMF2408601W082302129",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD230823FS02645",
				"callType": "FS",
				"productReferenceId": "268675",
				"productName": "CROMA W/M SEMI 7KG CRLW070SMF248601",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS3807455",
				"dateOfPurchase": "2023-07-27T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-22T18:30:00.000+00:00",
				"tat": 3.86,
				"servifyCallClosureDateTime": "0033-02-12T07:59:00.000+00:00",
				"servifySerialNumber": "CRLW070SMF248601W062301173",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC040823ID98307",
				"callType": "ID",
				"productReferenceId": "268388",
				"productName": "Croma W/M TL 11kg CRLW011FAF264503",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3851808",
				"dateOfPurchase": "2023-07-29T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 0.99,
				"servifyCallClosureDateTime": "0011-02-13T09:14:00.000+00:00",
				"servifySerialNumber": "CRLW011FAF264503072300611",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC140823ID79082",
				"callType": "ID",
				"productReferenceId": "268241",
				"productName": "CROMA REF INV 274L 3SBL CRLR274FID276254",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free <300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4097574",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.94,
				"servifyCallClosureDateTime": "0021-02-12T15:03:00.000+00:00",
				"servifySerialNumber": "CRLR274FID276254T062300042",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC200823ID57498",
				"callType": "ID",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4262981",
				"dateOfPurchase": "2023-08-18T18:30:00.000+00:00",
				"pincode": 361140,
				"servifyCallCreationDateTime": "2023-08-19T18:30:00.000+00:00",
				"tat": 0.85,
				"servifyCallClosureDateTime": "0027-02-13T10:23:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580723I8213",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID34867",
				"callType": "ID",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4215765",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.92,
				"servifyCallClosureDateTime": "0025-02-12T14:34:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580723I8132",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID07955",
				"callType": "ID",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4163521",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.87,
				"servifyCallClosureDateTime": "0023-02-13T12:50:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580723I8151",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD140823FS02463",
				"callType": "FS",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3731209",
				"dateOfPurchase": "2023-07-21T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 3.05,
				"servifyCallClosureDateTime": "0023-02-13T12:00:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580323I6422",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD160823FS02814",
				"callType": "FS",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2066677",
				"dateOfPurchase": "2023-05-13T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 1.1,
				"servifyCallClosureDateTime": "0023-02-13T14:07:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580323I6474",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD100823FS02271",
				"callType": "FS",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2066674",
				"dateOfPurchase": "2023-05-13T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-09T18:30:00.000+00:00",
				"tat": 1.19,
				"servifyCallClosureDateTime": "0017-02-12T15:07:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580323I6473",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD160823FS02804",
				"callType": "FS",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2066672",
				"dateOfPurchase": "2023-05-13T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 1.08,
				"servifyCallClosureDateTime": "0023-02-13T13:40:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580323I6427",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD030823FS03425",
				"callType": "FS",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1075279",
				"dateOfPurchase": "2023-03-25T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-02T18:30:00.000+00:00",
				"tat": 1.04,
				"servifyCallClosureDateTime": "0010-02-13T14:41:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702580323I6326",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD030823FS01403",
				"callType": "FS",
				"productReferenceId": "267928",
				"productName": "CROMA INV/AC 1.5T CRLA018IND170258 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SOA166009362288",
				"dateOfPurchase": "2023-03-24T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-02T18:30:00.000+00:00",
				"tat": 1.28,
				"servifyCallClosureDateTime": "0010-02-13T14:42:00.000+00:00",
				"servifySerialNumber": "CRLA018IND1702590323I6326",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290723FS03495",
				"callType": "FS",
				"productReferenceId": "267925",
				"productName": "CROMA S/AC 1T CRLA012SAD170233 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1740236",
				"dateOfPurchase": "2023-04-26T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-28T18:30:00.000+00:00",
				"tat": 2.68,
				"servifyCallClosureDateTime": "0007-02-13T05:24:00.000+00:00",
				"servifySerialNumber": "CRLA012SAD1702330223I0472",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS03926",
				"callType": "FS",
				"productReferenceId": "267356",
				"productName": "Croma 75L D Cooler AZ75 CRLC75LRCA231001",
				"warranty": "IW",
				"productCategory": "Air Cooler",
				"productSubCategory": "Room Coolers",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2717383",
				"dateOfPurchase": "2023-06-03T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 2.86,
				"servifyCallClosureDateTime": "0020-02-13T14:02:00.000+00:00",
				"servifySerialNumber": "CRLC75LRCA231001T052305974",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Air Flow",
				"l2": "Fan Motor Not Working",
				"l3": "Fan Motor Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 250.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 250.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823FS02854",
				"callType": "FS",
				"productReferenceId": "267356",
				"productName": "Croma 75L D Cooler AZ75 CRLC75LRCA231001",
				"warranty": "IW",
				"productCategory": "Air Cooler",
				"productSubCategory": "Room Coolers",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2165309",
				"dateOfPurchase": "2023-05-17T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 5.67,
				"servifyCallClosureDateTime": "0037-02-12T04:36:00.000+00:00",
				"servifySerialNumber": "CRLC75LRCA231001T04234901",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 250.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 250.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC150823ID98350",
				"callType": "ID",
				"productReferenceId": "267119",
				"productName": "CROMA INV/AC 2T CRLA024IND255354 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4139354",
				"dateOfPurchase": "2023-08-13T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.39,
				"servifyCallClosureDateTime": "0022-02-13T13:20:00.000+00:00",
				"servifySerialNumber": "CRLA024IND255354T0123I0807",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69961",
				"callType": "ID",
				"productReferenceId": "267119",
				"productName": "CROMA INV/AC 2T CRLA024IND255354 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4077255",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0020-02-13T12:45:00.000+00:00",
				"servifySerialNumber": "CRLA024IND255354T0223I1047",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS02686",
				"callType": "FS",
				"productReferenceId": "267119",
				"productName": "CROMA INV/AC 2T CRLA024IND255354 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2261790",
				"dateOfPurchase": "2023-05-19T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 2.97,
				"servifyCallClosureDateTime": "0037-02-12T11:08:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320223I0566",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS01104",
				"callType": "FS",
				"productReferenceId": "266717",
				"productName": "CROMA REF DC 215L 3S MG CRLR215DCD008903",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Direct Cool <300L",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS4164459",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 1.26,
				"servifyCallClosureDateTime": "0029-02-12T13:24:00.000+00:00",
				"servifySerialNumber": "CRLR215DCD008903T0747",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC240823ID84660",
				"callType": "ID",
				"productReferenceId": "266714",
				"productName": "CROMA REF INV 268L 3SMG CRLR268FID008952",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free <300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4321192",
				"dateOfPurchase": "2023-08-21T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 1.08,
				"servifyCallClosureDateTime": "0031-02-13T16:02:00.000+00:00",
				"servifySerialNumber": "CRLR268FID008952T07230110",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID34818",
				"callType": "ID",
				"productReferenceId": "266714",
				"productName": "CROMA REF INV 268L 3SMG CRLR268FID008952",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free <300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4215658",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0025-02-12T11:07:00.000+00:00",
				"servifySerialNumber": "CRLR268FID008952T04230069",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID21157",
				"callType": "ID",
				"productReferenceId": "266714",
				"productName": "CROMA REF INV 268L 3SMG CRLR268FID008952",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free <300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4185429",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 3.84,
				"servifyCallClosureDateTime": "0027-02-13T12:05:00.000+00:00",
				"servifySerialNumber": "CRLR268FID008952T04230103",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC240823ID80513",
				"callType": "ID",
				"productReferenceId": "266713",
				"productName": "CROMA REF INV 240L 3SMG CRLR240FID008951",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free <300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4312875",
				"dateOfPurchase": "2023-08-21T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0031-02-13T09:34:00.000+00:00",
				"servifySerialNumber": "CRLR268FID008952T07230110",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC190823ID20731",
				"callType": "ID",
				"productReferenceId": "266713",
				"productName": "CROMA REF INV 240L 3SMG CRLR240FID008951",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free <300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4184110",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.9,
				"servifyCallClosureDateTime": "0026-02-13T13:43:00.000+00:00",
				"servifySerialNumber": "CRLR240FID008951T06230281",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS02359",
				"callType": "FS",
				"productReferenceId": "266540",
				"productName": "Croma 55L T cooler AZ55 CRLC55LRCA284501",
				"warranty": "OW",
				"productCategory": "Air Cooler",
				"productSubCategory": "Room Coolers",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2397527",
				"dateOfPurchase": "2023-05-22T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.04,
				"servifyCallClosureDateTime": "0028-02-13T11:21:00.000+00:00",
				"servifySerialNumber": "CRLC55LRCA284501T042300128",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 80.0,
				"travelAllowance": 0.0,
				"total": -80.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS03691",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS758943",
				"dateOfPurchase": "2023-03-09T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 0.65,
				"servifyCallClosureDateTime": "0007-02-13T04:43:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0123I2880",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Noise Due To Mis Alignment",
				"l3": "Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS01422",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS758943",
				"dateOfPurchase": "2023-03-09T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 1.18,
				"servifyCallClosureDateTime": "0011-02-13T12:22:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0123I2880",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 3,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC150823ID97357",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4137279",
				"dateOfPurchase": "2023-08-13T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.16,
				"servifyCallClosureDateTime": "0022-02-13T07:57:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I1382",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC150823ID94139",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4131835",
				"dateOfPurchase": "2023-08-12T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.24,
				"servifyCallClosureDateTime": "0022-02-13T09:43:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I1087",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID73763",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4083763",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0020-02-13T10:58:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I0346",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69886",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4077149",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.76,
				"servifyCallClosureDateTime": "0020-02-13T10:49:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I0723",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69921",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4077141",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.71,
				"servifyCallClosureDateTime": "0020-02-13T10:58:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I0688",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69889",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4077139",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0020-02-13T10:54:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I1252",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69780",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076854",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.85,
				"servifyCallClosureDateTime": "0020-02-13T12:49:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I0473",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69775",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076850",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0020-02-13T12:47:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0323I2803",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69771",
				"callType": "ID",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076762",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.73,
				"servifyCallClosureDateTime": "0020-02-13T10:59:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222I0338",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD130823FS00429",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2719307",
				"dateOfPurchase": "2023-06-03T18:30:00.000+00:00",
				"pincode": 361280,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 4.02,
				"servifyCallClosureDateTime": "0023-02-13T06:20:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T1222D2365",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823FS00663",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2417785",
				"dateOfPurchase": "2023-05-23T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 5.25,
				"servifyCallClosureDateTime": "0036-02-13T12:25:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0323I4709",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS01407",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1969178",
				"dateOfPurchase": "2023-05-07T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 3.34,
				"servifyCallClosureDateTime": "0031-02-13T16:00:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0323I4821",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS03231",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1713762",
				"dateOfPurchase": "2023-04-24T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 0.99,
				"servifyCallClosureDateTime": "0028-02-13T12:02:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0323I4228",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS03078",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1659090",
				"dateOfPurchase": "2023-04-22T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0035-02-13T13:03:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0323I4265",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD090823FS01660",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Sealed System",
				"salesInvoiceNumber": "SLRS1519345",
				"dateOfPurchase": "2023-04-17T18:30:00.000+00:00",
				"pincode": 361141,
				"servifyCallCreationDateTime": "2023-08-08T18:30:00.000+00:00",
				"tat": 1.25,
				"servifyCallClosureDateTime": "0016-02-13T14:33:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0223I3671",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD180823FS00880",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1385448",
				"dateOfPurchase": "2023-04-12T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 3.14,
				"servifyCallClosureDateTime": "0027-02-13T10:11:00.000+00:00",
				"servifySerialNumber": "2.06E+17",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS02465",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS758943",
				"dateOfPurchase": "2023-03-09T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0032-02-13T10:46:00.000+00:00",
				"servifySerialNumber": "CREL018IND255352T0123I2880",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS00250",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2997470",
				"dateOfPurchase": "2023-06-09T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.36,
				"servifyCallClosureDateTime": "0032-02-13T13:50:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0323I4377",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS03034",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2981070",
				"dateOfPurchase": "2023-06-08T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.03,
				"servifyCallClosureDateTime": "0023-02-13T13:55:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0523I6486",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS03209",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2981060",
				"dateOfPurchase": "2023-06-08T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 1.94,
				"servifyCallClosureDateTime": "0010-02-13T11:35:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0523I6399",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS03089",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2105599",
				"dateOfPurchase": "2023-05-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0035-02-13T13:10:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255352T0423I5909",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS00981",
				"callType": "FS",
				"productReferenceId": "265701",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255352 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS1954366",
				"dateOfPurchase": "2023-05-06T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 5.16,
				"servifyCallClosureDateTime": "0037-02-12T10:44:00.000+00:00",
				"servifySerialNumber": "CRLA018IND254352T0323I4759",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID70564",
				"callType": "ID",
				"productReferenceId": "265638",
				"productName": "CROMA INV/AC 1T CRLA012IND255351 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4078266",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0020-02-13T12:48:00.000+00:00",
				"servifySerialNumber": "CRLA012IND255351T0323I2258",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID70508",
				"callType": "ID",
				"productReferenceId": "265638",
				"productName": "CROMA INV/AC 1T CRLA012IND255351 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4078182",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0020-02-13T11:01:00.000+00:00",
				"servifySerialNumber": "CRLA012IND255351T0123I0888",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69684",
				"callType": "ID",
				"productReferenceId": "265638",
				"productName": "CROMA INV/AC 1T CRLA012IND255351 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076597",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.76,
				"servifyCallClosureDateTime": "0020-02-13T10:51:00.000+00:00",
				"servifySerialNumber": "CRLA012IND255351T0323I2660",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC060823ID27350",
				"callType": "ID",
				"productReferenceId": "265638",
				"productName": "CROMA INV/AC 1T CRLA012IND255351 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3939446",
				"dateOfPurchase": "2023-08-04T18:30:00.000+00:00",
				"pincode": 361280,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 0.88,
				"servifyCallClosureDateTime": "0013-02-12T12:42:00.000+00:00",
				"servifySerialNumber": "CRLA012IND255351T0123I1123",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID71183",
				"callType": "ID",
				"productReferenceId": "265357",
				"productName": "CROMA INV/AC 2T CRLA024IND283253 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4079415",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.76,
				"servifyCallClosureDateTime": "0020-02-13T10:53:00.000+00:00",
				"servifySerialNumber": "CRLA024IND283253T0423I0162",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS01892",
				"callType": "FS",
				"productReferenceId": "264175",
				"productName": "CROMA INV/AC 1.4T CRLA017IND255953 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3584118",
				"dateOfPurchase": "2023-07-12T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 1.06,
				"servifyCallClosureDateTime": "0008-02-13T10:36:00.000+00:00",
				"servifySerialNumber": "CRLA017IND255953T0323I5272",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130723ID51937",
				"callType": "ID",
				"productReferenceId": "264175",
				"productName": "CROMA INV/AC 1.4T CRLA017IND255953 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3570459",
				"dateOfPurchase": "2023-07-10T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.19,
				"servifyCallClosureDateTime": "0025-02-12T11:05:00.000+00:00",
				"servifySerialNumber": "CRLA017IND255953T0223I3991",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS02031",
				"callType": "FS",
				"productReferenceId": "264175",
				"productName": "CROMA INV/AC 1.4T CRLA017IND255953 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2608411",
				"dateOfPurchase": "2023-05-29T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0007-02-13T10:18:00.000+00:00",
				"servifySerialNumber": "CRLA017IND255953T0223I3106",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS02652",
				"callType": "FS",
				"productReferenceId": "264175",
				"productName": "CROMA INV/AC 1.4T CRLA017IND255953 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1951350",
				"dateOfPurchase": "2023-05-06T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.08,
				"servifyCallClosureDateTime": "0035-02-13T13:00:00.000+00:00",
				"servifySerialNumber": "CRLA017IND255953T0123I2383",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 6,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS03705",
				"callType": "FS",
				"productReferenceId": "264175",
				"productName": "CROMA INV/AC 1.4T CRLA017IND255953 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1951350",
				"dateOfPurchase": "2023-05-06T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.78,
				"servifyCallClosureDateTime": "0029-02-12T08:59:00.000+00:00",
				"servifySerialNumber": "CRLA017IND255953T0123I2383",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS03017",
				"callType": "FS",
				"productReferenceId": "264175",
				"productName": "CROMA INV/AC 1.4T CRLA017IND255953 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SOA166351223618",
				"dateOfPurchase": "2023-06-02T18:30:00.000+00:00",
				"pincode": 361142,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.03,
				"servifyCallClosureDateTime": "0023-02-13T13:52:00.000+00:00",
				"servifySerialNumber": "CRLA017IND255953T0223I3609",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise, Set Dent And Damage",
				"l3": "Compressor Leg Adjusted #, Dent Removal #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS01494",
				"callType": "FS",
				"productReferenceId": "264172",
				"productName": "CROMA INV/AC 1T CRLA012IND255951 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1601169",
				"dateOfPurchase": "2023-04-20T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.27,
				"servifyCallClosureDateTime": "0030-02-13T14:45:00.000+00:00",
				"servifySerialNumber": "CRLA012IND255951T0123I0528",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS02700",
				"callType": "FS",
				"productReferenceId": "263776",
				"productName": "CROMA INV/AC 2T CRLA024IND170257 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC2542375",
				"dateOfPurchase": "2023-05-26T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 1.93,
				"servifyCallClosureDateTime": "0009-02-12T10:25:00.000+00:00",
				"servifySerialNumber": "CRLA024IND1702570223I0482",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC150823ID97743",
				"callType": "ID",
				"productReferenceId": "263761",
				"productName": "CROMA INV/AC 1.5T CRLA018INF170256 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4137569",
				"dateOfPurchase": "2023-08-13T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.94,
				"servifyCallClosureDateTime": "0023-02-13T03:10:00.000+00:00",
				"servifySerialNumber": "CRLA018INF1702560423I1925",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC290723ID51949",
				"callType": "ID",
				"productReferenceId": "263758",
				"productName": "CROMA INV/AC 1.2T CRLA014IND170253 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3810197",
				"dateOfPurchase": "2023-07-27T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.18,
				"servifyCallClosureDateTime": "0025-02-12T10:57:00.000+00:00",
				"servifySerialNumber": "CRLA014IND1702530123I0277",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS01630",
				"callType": "FS",
				"productReferenceId": "263758",
				"productName": "CROMA INV/AC 1.2T CRLA014IND170253 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2636206",
				"dateOfPurchase": "2023-05-30T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.15,
				"servifyCallClosureDateTime": "0028-02-13T12:03:00.000+00:00",
				"servifySerialNumber": "CRLA014IND1702530223I0361",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS02565",
				"callType": "FS",
				"productReferenceId": "263758",
				"productName": "CROMA INV/AC 1.2T CRLA014IND170253 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS1952525",
				"dateOfPurchase": "2023-05-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 1.07,
				"servifyCallClosureDateTime": "0035-02-13T13:11:00.000+00:00",
				"servifySerialNumber": "CRLA014IND1702530223I0583",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS03935",
				"callType": "FS",
				"productReferenceId": "263286",
				"productName": "E-CROMA LED HDR 24IN 1Y CREL024HBB024602",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED < 32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC4021487",
				"dateOfPurchase": "2023-08-08T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 7.24,
				"servifyCallClosureDateTime": "0035-02-13T10:16:00.000+00:00",
				"servifySerialNumber": "CREL024HBB024602T072306341",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 10,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS01728",
				"callType": "FS",
				"productReferenceId": "263286",
				"productName": "E-CROMA LED HDR 24IN 1Y CREL024HBB024602",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED < 32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRE004SLTC3490450",
				"dateOfPurchase": "2023-07-06T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.05,
				"servifyCallClosureDateTime": "0008-02-13T10:02:00.000+00:00",
				"servifySerialNumber": "CREL024HBB024602T042303677",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 435.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 435.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC100823ID54014",
				"callType": "ID",
				"productReferenceId": "263286",
				"productName": "E-CROMA LED HDR 24IN 1Y CREL024HBB024602",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED < 32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS4014402",
				"dateOfPurchase": "2023-08-08T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-09T18:30:00.000+00:00",
				"tat": 2.08,
				"servifyCallClosureDateTime": "0018-02-13T13:20:00.000+00:00",
				"servifySerialNumber": "CREL024HBB024602T072306343",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS00315",
				"callType": "FS",
				"productReferenceId": "263286",
				"productName": "E-CROMA LED HDR 24IN 1Y CREL024HBB024602",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED < 32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS4014402",
				"dateOfPurchase": "2023-08-08T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 0.08,
				"servifyCallClosureDateTime": "0017-02-12T07:19:00.000+00:00",
				"servifySerialNumber": "CREL024HBB024602T072306343",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 435.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 435.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS03605",
				"callType": "FS",
				"productReferenceId": "261113",
				"productName": "Croma Cooktop 3B SS CRLG3SSGSA279201",
				"warranty": "IW",
				"productCategory": "Oven Toaster Grill",
				"productSubCategory": "Gas StovesCooktops",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166009107903",
				"dateOfPurchase": "2023-03-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 2.01,
				"servifyCallClosureDateTime": "0029-02-12T13:32:00.000+00:00",
				"servifySerialNumber": "CRLG3GTGSA289207",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Heating",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD120823FS02884",
				"callType": "FS",
				"productReferenceId": "258994",
				"productName": "Croma WP RO+UV+CU CRLW7ROWPA254202",
				"warranty": "IW",
				"productCategory": "Water Purifier",
				"productSubCategory": "Water Purifiers RO",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2073870",
				"dateOfPurchase": "2023-05-13T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 3.95,
				"servifyCallClosureDateTime": "0022-02-13T09:53:00.000+00:00",
				"servifySerialNumber": "CRLU7ROWPA2542027112200513",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Set Dead",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC280823ID11243",
				"callType": "ID",
				"productReferenceId": "258104",
				"productName": "Croma W/M TL 7.5Kg CRLW075FAF276204",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4379847",
				"dateOfPurchase": "2023-08-26T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 0.68,
				"servifyCallClosureDateTime": "0035-02-13T06:31:00.000+00:00",
				"servifySerialNumber": "CRLW075FAF276204082303882",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC020823ID07194",
				"callType": "ID",
				"productReferenceId": "258104",
				"productName": "Croma W/M TL 7.5Kg CRLW075FAF276204",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3874701",
				"dateOfPurchase": "2023-07-31T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.72,
				"servifyCallClosureDateTime": "0009-02-12T06:54:00.000+00:00",
				"servifySerialNumber": "CRLW075FAF276204072302258",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS00136",
				"callType": "FS",
				"productReferenceId": "258104",
				"productName": "Croma W/M TL 7.5Kg CRLW075FAF276204",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3874701",
				"dateOfPurchase": "2023-07-31T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 2.21,
				"servifyCallClosureDateTime": "0032-02-13T09:38:00.000+00:00",
				"servifySerialNumber": "CRLW075FAF276204072302258",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 21,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170723ID51912",
				"callType": "ID",
				"productReferenceId": "258104",
				"productName": "Croma W/M TL 7.5Kg CRLW075FAF276204",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3646046",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.2,
				"servifyCallClosureDateTime": "0025-02-12T11:16:00.000+00:00",
				"servifySerialNumber": "CRLW075FAF276204072302300",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS00951",
				"callType": "FS",
				"productReferenceId": "257199",
				"productName": "E-CROMA W/M Semi 6.5Kg CRLW065SMF202351",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9412612",
				"dateOfPurchase": "2022-10-24T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 2.22,
				"servifyCallClosureDateTime": "0010-02-13T12:18:00.000+00:00",
				"servifySerialNumber": "CRLW065SMF202351072205085",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Spin Not Working",
				"l2": "Spin Motor Stuck (Rotor Jam/Coil Open)",
				"l3": "Spin Motor Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS00876",
				"callType": "FS",
				"productReferenceId": "257199",
				"productName": "E-CROMA W/M Semi 6.5Kg CRLW065SMF202351",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRE004SLTC4003877",
				"dateOfPurchase": "2023-08-08T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 4.2,
				"servifyCallClosureDateTime": "0027-02-13T11:49:00.000+00:00",
				"servifySerialNumber": "CRLW065SMF202351072300937",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD060823FS00446",
				"callType": "FS",
				"productReferenceId": "257199",
				"productName": "E-CROMA W/M Semi 6.5Kg CRLW065SMF202351",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SOA166352181575",
				"dateOfPurchase": "2023-07-08T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 2.98,
				"servifyCallClosureDateTime": "0015-02-13T05:22:00.000+00:00",
				"servifySerialNumber": "CRLW065SMF202351062302046",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS02593",
				"callType": "FS",
				"productReferenceId": "257199",
				"productName": "E-CROMA W/M Semi 6.5Kg CRLW065SMF202351",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166007496423",
				"dateOfPurchase": "2023-01-07T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.09,
				"servifyCallClosureDateTime": "0035-02-13T13:07:00.000+00:00",
				"servifySerialNumber": "CRAW065SMF202351112200993",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC190723ID51917",
				"callType": "ID",
				"productReferenceId": "256921",
				"productName": "Croma 1.5Ton Portble AC CRLA018PAA025801",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Portable Air Conditioners",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3672063",
				"dateOfPurchase": "2023-07-16T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.19,
				"servifyCallClosureDateTime": "0025-02-12T11:08:00.000+00:00",
				"servifySerialNumber": "CRLA018PAA025801042300214",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID70750",
				"callType": "ID",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4078607",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 2.85,
				"servifyCallClosureDateTime": "0022-02-13T12:53:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302072301897",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC050823ID21198",
				"callType": "ID",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3923764",
				"dateOfPurchase": "2023-08-03T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 0.9,
				"servifyCallClosureDateTime": "0012-02-13T11:06:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302072301748",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC200723ID51928",
				"callType": "ID",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3672410",
				"dateOfPurchase": "2023-07-17T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.19,
				"servifyCallClosureDateTime": "0025-02-12T11:14:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302042300145",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160723ID15572",
				"callType": "ID",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3619568",
				"dateOfPurchase": "2023-07-13T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-02T18:30:00.000+00:00",
				"tat": 0.86,
				"servifyCallClosureDateTime": "0010-02-13T09:47:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302062301265",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD140823FS01513",
				"callType": "FS",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3280325",
				"dateOfPurchase": "2023-06-23T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 2.93,
				"servifyCallClosureDateTime": "0023-02-13T06:26:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302052300846",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS03612",
				"callType": "FS",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS2638299",
				"dateOfPurchase": "2023-05-30T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 2.01,
				"servifyCallClosureDateTime": "0029-02-12T13:27:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302052300591",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS02590",
				"callType": "FS",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2707115",
				"dateOfPurchase": "2023-06-03T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.08,
				"servifyCallClosureDateTime": "0035-02-13T13:01:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202302052300718",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS00685",
				"callType": "FS",
				"productReferenceId": "252909",
				"productName": "Croma W/M TL 8kg CRLW080FAF202302 With Heater",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2638299",
				"dateOfPurchase": "2023-05-30T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 2.3,
				"servifyCallClosureDateTime": "0029-02-12T13:29:00.000+00:00",
				"servifySerialNumber": "CRLW080FAF202392052300591",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD150823FS02580",
				"callType": "FS",
				"productReferenceId": "252134",
				"productName": "Croma INV REF 337L 3S CRLR340FFD259608",
				"warranty": "OW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free >300L",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS6100856",
				"dateOfPurchase": "2022-06-08T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-14T18:30:00.000+00:00",
				"tat": 1.99,
				"servifyCallClosureDateTime": "0023-02-13T12:53:00.000+00:00",
				"servifySerialNumber": "CRLR340FFD259608052200455",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Compressor Noise",
				"l2": "Voltage Fluctuation",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823FS02417",
				"callType": "FS",
				"productReferenceId": "252131",
				"productName": "E-Croma INV REF 307L 2S CRLR310FFC259603",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free >300L",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS9962791",
				"dateOfPurchase": "2022-11-21T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 3.91,
				"servifyCallClosureDateTime": "0017-02-12T08:24:00.000+00:00",
				"servifySerialNumber": "CRLR310FFC259603062200406",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Compressor Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS00974",
				"callType": "FS",
				"productReferenceId": "252130",
				"productName": "Croma INV REF 279L 2S CRLR280FFC259602",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free >300L",
				"oss": "Others",
				"salesInvoiceNumber": "CRE074SLE074EA8829890",
				"dateOfPurchase": "2022-10-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 1.27,
				"servifyCallClosureDateTime": "0007-02-13T13:30:00.000+00:00",
				"servifySerialNumber": "CRLR280FFC259602082200338",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Compressor Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823RF00005",
				"callType": "RF",
				"productReferenceId": "252064",
				"productName": "Croma W/M semi 10kg CRLW100SMF231001",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-10-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 2.07,
				"servifyCallClosureDateTime": "0037-02-12T07:32:00.000+00:00",
				"servifySerialNumber": "CRLW100SMF231001052302810",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 26,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823RF00058",
				"callType": "RF",
				"productReferenceId": "252064",
				"productName": "Croma W/M semi 10kg CRLW100SMF231001",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-11-21T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 2.35,
				"servifyCallClosureDateTime": "0010-02-13T14:44:00.000+00:00",
				"servifySerialNumber": "CRLW100SMF231001052302810",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD120823FS02304",
				"callType": "FS",
				"productReferenceId": "252064",
				"productName": "Croma W/M semi 10kg CRLW100SMF231001",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2270059",
				"dateOfPurchase": "2023-05-20T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 2.12,
				"servifyCallClosureDateTime": "0020-02-13T12:38:00.000+00:00",
				"servifySerialNumber": "CRLW100SMF231001042302147",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID02284",
				"callType": "ID",
				"productReferenceId": "251389",
				"productName": "E-CROMA LEDUHDWOS 55 1Y CREL055USA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4151279",
				"dateOfPurchase": "2023-08-13T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.92,
				"servifyCallClosureDateTime": "0023-02-13T14:11:00.000+00:00",
				"servifySerialNumber": "CREL055USA024601T082308474",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC220823ID68143",
				"callType": "ID",
				"productReferenceId": "251388",
				"productName": "E-CROMA LEDUHDWOS 50 1Y CREL050USA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC4284698",
				"dateOfPurchase": "2023-08-19T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.61,
				"servifyCallClosureDateTime": "0029-02-12T06:36:00.000+00:00",
				"servifySerialNumber": "CREL050USA024601T072309379",
				"technician": "Sameer Godhaviya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID08816",
				"callType": "ID",
				"productReferenceId": "251388",
				"productName": "E-CROMA LEDUHDWOS 50 1Y CREL050USA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4164875",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.63,
				"servifyCallClosureDateTime": "0023-02-13T06:45:00.000+00:00",
				"servifySerialNumber": "CREL050USA024601T072309825",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID05263",
				"callType": "ID",
				"productReferenceId": "251388",
				"productName": "E-CROMA LEDUHDWOS 50 1Y CREL050USA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEC4156902",
				"dateOfPurchase": "2023-08-13T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.8,
				"servifyCallClosureDateTime": "0023-02-13T13:09:00.000+00:00",
				"servifySerialNumber": "CREL050USA024601T102206093",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID26076",
				"callType": "ID",
				"productReferenceId": "251015",
				"productName": "CROMA LEDUHD OA 65IN 1Y CREL065UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED Panels 65",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4194369",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.73,
				"servifyCallClosureDateTime": "0024-02-13T09:39:00.000+00:00",
				"servifySerialNumber": "CREL065UOA024601T072200842",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC110823ID63884",
				"callType": "ID",
				"productReferenceId": "251013",
				"productName": "Croma LedUHD OA 50in 1Y CREL050UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4056428",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361010,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 0.61,
				"servifyCallClosureDateTime": "0018-02-13T06:46:00.000+00:00",
				"servifySerialNumber": "CREL050UOA024601T062200197",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS01392",
				"callType": "FS",
				"productReferenceId": "251013",
				"productName": "Croma LedUHD OA 50in 1Y CREL050UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS8267914",
				"dateOfPurchase": "2022-09-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.2,
				"servifyCallClosureDateTime": "0032-02-13T12:30:00.000+00:00",
				"servifySerialNumber": "CREL050UOA024601T082203992",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS01267",
				"callType": "FS",
				"productReferenceId": "251013",
				"productName": "Croma LedUHD OA 50in 1Y CREL050UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS8267914",
				"dateOfPurchase": "2022-09-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.21,
				"servifyCallClosureDateTime": "0032-02-13T12:28:00.000+00:00",
				"servifySerialNumber": "CRLA050UOA024601T082203992",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS02260",
				"callType": "FS",
				"productReferenceId": "251012",
				"productName": "Croma LedUHD OA 43in 1Y CREL043UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS1144983",
				"dateOfPurchase": "2023-03-29T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.95,
				"servifyCallClosureDateTime": "0031-02-13T09:17:00.000+00:00",
				"servifySerialNumber": "CREL043U0A024601T022313519",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD230823FS00786",
				"callType": "FS",
				"productReferenceId": "251012",
				"productName": "Croma LedUHD OA 43in 1Y CREL043UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Panel Replaced",
				"salesInvoiceNumber": "SLA166RS8143845",
				"dateOfPurchase": "2022-08-29T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-22T18:30:00.000+00:00",
				"tat": 8.33,
				"servifyCallClosureDateTime": "0037-02-12T14:38:00.000+00:00",
				"servifySerialNumber": "CREL043UOA024601T082204680",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Picture",
				"l2": "Panel Defective",
				"l3": "Panel Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 855.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 855.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS02243",
				"callType": "FS",
				"productReferenceId": "251012",
				"productName": "Croma LedUHD OA 43in 1Y CREL043UOA024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA210SLRS1144983",
				"dateOfPurchase": "2023-03-29T18:30:00.000+00:00",
				"pincode": 392001,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.95,
				"servifyCallClosureDateTime": "0031-02-13T09:19:00.000+00:00",
				"servifySerialNumber": "CREL043UOA024601T022213519",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Main Board",
				"l3": "Main Board Repaired $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS00494",
				"callType": "FS",
				"productReferenceId": "251011",
				"productName": "Croma LedFHD OA 43in 1Y CREL043FOE024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Panel Replaced",
				"salesInvoiceNumber": "SLA166RS8806330",
				"dateOfPurchase": "2022-10-03T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 4.31,
				"servifyCallClosureDateTime": "0010-02-13T13:30:00.000+00:00",
				"servifySerialNumber": "CREL043FOE024601T0822062326",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Picture",
				"l2": "Panel Defective",
				"l3": "Panel Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 855.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 855.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS00841",
				"callType": "FS",
				"productReferenceId": "251011",
				"productName": "Croma LedFHD OA 43in 1Y CREL043FOE024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS527627",
				"dateOfPurchase": "2023-01-07T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 1.32,
				"servifyCallClosureDateTime": "0018-02-13T14:22:00.000+00:00",
				"servifySerialNumber": "CREL034FOE024601T112212293",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD300723FS03277",
				"callType": "FS",
				"productReferenceId": "251011",
				"productName": "Croma LedFHD OA 43in 1Y CREL043FOE024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS122344",
				"dateOfPurchase": "2022-12-16T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-29T18:30:00.000+00:00",
				"tat": 1.64,
				"servifyCallClosureDateTime": "0007-02-13T05:16:00.000+00:00",
				"servifySerialNumber": "CREL043FOE024601T112212386",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Noise Due To Wrong Sound Setting",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD300723FS01450",
				"callType": "FS",
				"productReferenceId": "251010",
				"productName": "E-Croma LedFHD OA 40in 1Y CREL040FOF024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3640423",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-07-29T18:30:00.000+00:00",
				"tat": 2.06,
				"servifyCallClosureDateTime": "0007-02-13T10:26:00.000+00:00",
				"servifySerialNumber": "CREL040FOF024601T062324337",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS02825",
				"callType": "FS",
				"productReferenceId": "251010",
				"productName": "E-Croma LedFHD OA 40in 1Y CREL040FOF024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9228505",
				"dateOfPurchase": "2022-10-19T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 0.86,
				"servifyCallClosureDateTime": "0007-02-13T07:56:00.000+00:00",
				"servifySerialNumber": "CREL040FOF024601T092212806",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS00325",
				"callType": "FS",
				"productReferenceId": "251010",
				"productName": "E-Croma LedFHD OA 40in 1Y CREL040FOF024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9228505",
				"dateOfPurchase": "2022-10-19T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 2.35,
				"servifyCallClosureDateTime": "0009-02-12T13:39:00.000+00:00",
				"servifySerialNumber": "CREL040FOF024601T092212806",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823FS00443",
				"callType": "FS",
				"productReferenceId": "251010",
				"productName": "E-Croma LedFHD OA 40in 1Y CREL040FOF024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRE004TC300038497462",
				"dateOfPurchase": "2022-12-04T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0034-02-13T07:04:00.000+00:00",
				"servifySerialNumber": "CREL040FOF024601T112216899",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823EW00362",
				"callType": "EW",
				"productReferenceId": "251010",
				"productName": "E-Croma LedFHD OA 40in 1Y CREL040FOF024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS7404004",
				"dateOfPurchase": "2022-07-29T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.17,
				"servifyCallClosureDateTime": "0030-02-13T11:41:00.000+00:00",
				"servifySerialNumber": "CREL040FOF024601T072215347",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 400.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 400.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS00672",
				"callType": "FS",
				"productReferenceId": "247856",
				"productName": "CROMA INV/AC 1T CRLA012INF255304 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9503607",
				"dateOfPurchase": "2022-10-27T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 2.34,
				"servifyCallClosureDateTime": "0025-02-12T14:37:00.000+00:00",
				"servifySerialNumber": "CRLA012INF255304T0622I0998",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 11,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS02675",
				"callType": "FS",
				"productReferenceId": "247856",
				"productName": "CROMA INV/AC 1T CRLA012INF255304 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9503607",
				"dateOfPurchase": "2022-10-27T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 2.14,
				"servifyCallClosureDateTime": "0013-02-12T14:10:00.000+00:00",
				"servifySerialNumber": "CRLA012INF255304T0622I0998",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS03604",
				"callType": "FS",
				"productReferenceId": "247850",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255302 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9381377",
				"dateOfPurchase": "2022-10-23T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 1.8,
				"servifyCallClosureDateTime": "0013-02-12T08:44:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255302T0322I3279",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS02945",
				"callType": "FS",
				"productReferenceId": "247850",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255302 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS8850657",
				"dateOfPurchase": "2022-10-05T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 3.05,
				"servifyCallClosureDateTime": "0013-02-12T13:26:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255302T0322I3562",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS03278",
				"callType": "FS",
				"productReferenceId": "247850",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255302 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS9527119",
				"dateOfPurchase": "2022-10-28T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0018-02-13T14:19:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255302T0622I6253",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 3,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD080823FS00702",
				"callType": "FS",
				"productReferenceId": "247850",
				"productName": "CROMA INV/AC 1.5T CRLA018IND255302 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS9527119",
				"dateOfPurchase": "2022-10-28T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 1.27,
				"servifyCallClosureDateTime": "0015-02-13T12:59:00.000+00:00",
				"servifySerialNumber": "CRLA018IND255302T0622I6253",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS00524",
				"callType": "FS",
				"productReferenceId": "247707",
				"productName": "CROMA INV/AC 1T CRLA012IND255301 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS40214",
				"dateOfPurchase": "2023-01-25T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 1.28,
				"servifyCallClosureDateTime": "0029-02-12T12:43:00.000+00:00",
				"servifySerialNumber": "CRLA012IND255301T0522I1173",
				"technician": "Sameer Godhaviya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS03867",
				"callType": "FS",
				"productReferenceId": "247599",
				"productName": "E-Croma LedHD OA 32in1Y CREL032HOF024601",
				"warranty": "OW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS7268118",
				"dateOfPurchase": "2022-07-23T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 2.84,
				"servifyCallClosureDateTime": "0010-02-13T11:30:00.000+00:00",
				"servifySerialNumber": "CREL032HOF024601T072223017",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823RF00109",
				"callType": "RF",
				"productReferenceId": "247599",
				"productName": "E-Croma LedHD OA 32in1Y CREL032HOF024601",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166006151293",
				"dateOfPurchase": "2022-11-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 1.06,
				"servifyCallClosureDateTime": "0018-02-13T13:13:00.000+00:00",
				"servifySerialNumber": "CREL032HOF024601T102235611",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS00875",
				"callType": "FS",
				"productReferenceId": "247101",
				"productName": "CROMA INV/AC 1.5T CRLAIN0185T0257 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS38576",
				"dateOfPurchase": "2022-12-10T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 4.28,
				"servifyCallClosureDateTime": "0036-02-13T13:25:00.000+00:00",
				"servifySerialNumber": "CRLAIN0185T02570522I3090",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823FS02397",
				"callType": "FS",
				"productReferenceId": "247098",
				"productName": "CROMA INV/AC 1T CRLAIN0125T0256 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9672616",
				"dateOfPurchase": "2022-11-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 1.85,
				"servifyCallClosureDateTime": "0015-02-13T06:43:00.000+00:00",
				"servifySerialNumber": "CRLAIN0125T02561022I3157",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS02721",
				"callType": "FS",
				"productReferenceId": "247095",
				"productName": "CROMA INV/AC 1.6T CRLAIN0193T0254 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLE004EC8395926",
				"dateOfPurchase": "2022-09-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0011-02-13T12:25:00.000+00:00",
				"servifySerialNumber": "CRLAIN0193T02540422I1849",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS03201",
				"callType": "FS",
				"productReferenceId": "247095",
				"productName": "CROMA INV/AC 1.6T CRLAIN0193T0254 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS8271628",
				"dateOfPurchase": "2022-09-05T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 1.05,
				"servifyCallClosureDateTime": "0033-02-12T13:37:00.000+00:00",
				"servifySerialNumber": "CRLAIN0193T02540422I1835",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823FS02543",
				"callType": "FS",
				"productReferenceId": "247089",
				"productName": "E-CROMA INV/AC 1.5T CRLAIN0183T0252 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRE004SLE004EC7649956",
				"dateOfPurchase": "2022-08-08T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 1.78,
				"servifyCallClosureDateTime": "0015-02-13T05:24:00.000+00:00",
				"servifySerialNumber": "CRLAIN0125T02561022I3157",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS00555",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS977825",
				"dateOfPurchase": "2023-03-20T18:30:00.000+00:00",
				"pincode": 361009,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 3.2,
				"servifyCallClosureDateTime": "0035-02-13T10:45:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320223I0589",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 24,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS00063",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS977825",
				"dateOfPurchase": "2023-03-20T18:30:00.000+00:00",
				"pincode": 361009,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.36,
				"servifyCallClosureDateTime": "0008-02-13T10:05:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320223I0589",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC180823ID39793",
				"callType": "ID",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4226625",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 0.91,
				"servifyCallClosureDateTime": "0025-02-12T14:28:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320323I0946",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 500.0,
				"travelAllowance": 0.0,
				"total": -500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS00226",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS208191",
				"dateOfPurchase": "2023-02-03T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 2.25,
				"servifyCallClosureDateTime": "0037-02-12T10:49:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02321222I0302",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290723FS01358",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS7916039",
				"dateOfPurchase": "2022-08-17T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-28T18:30:00.000+00:00",
				"tat": 2.91,
				"servifyCallClosureDateTime": "0007-02-13T05:36:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320622I3804",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS02691",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2261793",
				"dateOfPurchase": "2023-05-19T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 2.97,
				"servifyCallClosureDateTime": "0037-02-12T11:07:00.000+00:00",
				"servifySerialNumber": "CRLA024IND255354T0323I1237",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD060823FS00829",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2004652",
				"dateOfPurchase": "2023-05-09T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 1.16,
				"servifyCallClosureDateTime": "0013-02-12T10:43:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320223I0536",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS02419",
				"callType": "FS",
				"productReferenceId": "247083",
				"productName": "CROMA S/AC 1.5T CRLASA0183T0232 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS2001693",
				"dateOfPurchase": "2023-05-09T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 0.86,
				"servifyCallClosureDateTime": "0008-02-13T07:28:00.000+00:00",
				"servifySerialNumber": "CRLASA0183T02320223I0617",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823FS01117",
				"callType": "FS",
				"productReferenceId": "247080",
				"productName": "CROMA S/AC 1T CRLASA0123T0231 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS747744",
				"dateOfPurchase": "2023-03-08T18:30:00.000+00:00",
				"pincode": 361110,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0032-02-13T08:15:00.000+00:00",
				"servifySerialNumber": "CRLASA0123T02311222I2504",
				"technician": "Sameer Godhaviya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 375.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 375.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC170823ID21205",
				"callType": "ID",
				"productReferenceId": "246893",
				"productName": "Croma Led UHD FireTv 55in El7368 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4185047",
				"dateOfPurchase": "2023-08-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.81,
				"servifyCallClosureDateTime": "0024-02-13T11:34:00.000+00:00",
				"servifySerialNumber": "CREL7368Y1072314960",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID74774",
				"callType": "ID",
				"productReferenceId": "246893",
				"productName": "Croma Led UHD FireTv 55in El7368 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4085644",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0020-02-13T13:41:00.000+00:00",
				"servifySerialNumber": "CREL7368Y1062313718",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC080823ID41651",
				"callType": "ID",
				"productReferenceId": "246893",
				"productName": "Croma Led UHD FireTv 55in El7368 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3975445",
				"dateOfPurchase": "2023-08-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.98,
				"servifyCallClosureDateTime": "0015-02-13T12:01:00.000+00:00",
				"servifySerialNumber": "CREL7368Y1062314131",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC040823ID15146",
				"callType": "ID",
				"productReferenceId": "246893",
				"productName": "Croma Led UHD FireTv 55in El7368 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3904736",
				"dateOfPurchase": "2023-08-01T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 0.73,
				"servifyCallClosureDateTime": "0011-02-13T06:38:00.000+00:00",
				"servifySerialNumber": "CREL7368Y1062314124",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC310723ID98987",
				"callType": "ID",
				"productReferenceId": "246893",
				"productName": "Croma Led UHD FireTv 55in El7368 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3852979",
				"dateOfPurchase": "2023-07-29T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 0.99,
				"servifyCallClosureDateTime": "0007-02-13T14:22:00.000+00:00",
				"servifySerialNumber": "CREL7368Y1052814049",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC260823ID95154",
				"callType": "ID",
				"productReferenceId": "246892",
				"productName": "Croma Led UHD FireTv 50in El7367 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4345714",
				"dateOfPurchase": "2023-08-24T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.73,
				"servifyCallClosureDateTime": "0033-02-12T09:36:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1072311840",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC140823ID85110",
				"callType": "ID",
				"productReferenceId": "246892",
				"productName": "Croma Led UHD FireTv 50in El7367 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4108083",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.67,
				"servifyCallClosureDateTime": "0021-02-12T07:38:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1032311166",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS03562",
				"callType": "FS",
				"productReferenceId": "246892",
				"productName": "Croma Led UHD FireTv 50in El7367 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS8472100",
				"dateOfPurchase": "2022-09-19T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 0.73,
				"servifyCallClosureDateTime": "0018-02-13T07:52:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1072206655",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Defective",
				"l2": "Remote LED Not Working",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823RF00055",
				"callType": "RF",
				"productReferenceId": "246892",
				"productName": "Croma Led UHD FireTv 50in El7367 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-09-19T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 3.06,
				"servifyCallClosureDateTime": "0033-02-12T08:05:00.000+00:00",
				"servifySerialNumber": "CEL7364Y1032314675",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Not Working",
				"l2": "No Commands From Mike",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD120823FS03296",
				"callType": "FS",
				"productReferenceId": "246892",
				"productName": "Croma Led UHD FireTv 50in El7367 1yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SOA166008486519",
				"dateOfPurchase": "2023-02-16T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 3.11,
				"servifyCallClosureDateTime": "0021-02-12T15:04:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1012309280",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC080823ID23755",
				"callType": "ID",
				"productReferenceId": "246891",
				"productName": "Croma Led UHD FireTv 43in EL7366 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3931154",
				"dateOfPurchase": "2023-08-03T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:37:00.000+00:00",
				"servifySerialNumber": "CREL7366Y1012313617",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290723FS02850",
				"callType": "FS",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS72323",
				"dateOfPurchase": "2023-01-26T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-07-28T18:30:00.000+00:00",
				"tat": 3.08,
				"servifyCallClosureDateTime": "0007-02-13T13:27:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1082212040",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC140823ID86277",
				"callType": "ID",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4110010",
				"dateOfPurchase": "2023-08-12T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.82,
				"servifyCallClosureDateTime": "0021-02-12T11:16:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1072318390",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID74541",
				"callType": "ID",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4085191",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.66,
				"servifyCallClosureDateTime": "0020-02-13T10:27:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1072318395",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID69753",
				"callType": "ID",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4076786",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.77,
				"servifyCallClosureDateTime": "0020-02-13T11:02:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1022316223",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC120823ID62342",
				"callType": "ID",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4053456",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 0.92,
				"servifyCallClosureDateTime": "0019-02-13T12:33:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1022316116",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS01777",
				"callType": "FS",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4053456",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0029-02-12T09:28:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1022316116",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 9,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC060823ID27198",
				"callType": "ID",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3939208",
				"dateOfPurchase": "2023-08-04T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0013-02-12T11:37:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1072318458",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD200823FS00263",
				"callType": "FS",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3827417",
				"dateOfPurchase": "2023-07-28T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-19T18:30:00.000+00:00",
				"tat": 1.22,
				"servifyCallClosureDateTime": "0027-02-13T10:31:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1082212038",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD080823FS03415",
				"callType": "FS",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3186845",
				"dateOfPurchase": "2023-07-14T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 2.67,
				"servifyCallClosureDateTime": "0017-02-12T06:18:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1082212538",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823FS00657",
				"callType": "FS",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9126476",
				"dateOfPurchase": "2022-10-16T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 1.32,
				"servifyCallClosureDateTime": "0032-02-13T14:03:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1072211611",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID28266",
				"callType": "ID",
				"productReferenceId": "246890",
				"productName": "Croma Led FHD FireTv 43in EL7365 1Yr Wty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166353258010",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.9,
				"servifyCallClosureDateTime": "0023-02-13T08:30:00.000+00:00",
				"servifySerialNumber": "CREL7365Y1062318180",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 300.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 300.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823FS02050",
				"callType": "FS",
				"productReferenceId": "246869",
				"productName": "CROMA INV/AC 1T CRLAIN0125T8654 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4127572",
				"dateOfPurchase": "2022-02-26T18:30:00.000+00:00",
				"pincode": 361305,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 0.24,
				"servifyCallClosureDateTime": "0031-02-13T15:57:00.000+00:00",
				"servifySerialNumber": "CRLAIN0125T86540122I0109",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Led Not Glow",
				"l2": "Remote Control Led Fail",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823EW00485",
				"callType": "EW",
				"productReferenceId": "246869",
				"productName": "CROMA INV/AC 1T CRLAIN0125T8654 5S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4127572",
				"dateOfPurchase": "2022-02-26T18:30:00.000+00:00",
				"pincode": 361305,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 0.24,
				"servifyCallClosureDateTime": "0031-02-13T15:53:00.000+00:00",
				"servifySerialNumber": "CRLAIN0125T86540122I0109",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Led Not Glow",
				"l2": "Remote Control Led Fail",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD200823FS00658",
				"callType": "FS",
				"productReferenceId": "246866",
				"productName": "CROMA INV/AC 1.4T CRLAIN0173T8652 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4905977",
				"dateOfPurchase": "2022-04-16T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-19T18:30:00.000+00:00",
				"tat": 1.17,
				"servifyCallClosureDateTime": "0027-02-13T10:35:00.000+00:00",
				"servifySerialNumber": "CRLAIN0173T86520322I4549",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS00642",
				"callType": "FS",
				"productReferenceId": "246863",
				"productName": "CROMA INV/AC 1T CRLAIN0123T8651 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4778918",
				"dateOfPurchase": "2022-04-09T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 2.34,
				"servifyCallClosureDateTime": "0013-02-12T14:13:00.000+00:00",
				"servifySerialNumber": "CRLAIN0123T86510322I1487",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS01204",
				"callType": "FS",
				"productReferenceId": "246863",
				"productName": "CROMA INV/AC 1T CRLAIN0123T8651 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS112210",
				"dateOfPurchase": "2022-12-16T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 1.29,
				"servifyCallClosureDateTime": "0007-02-13T14:26:00.000+00:00",
				"servifySerialNumber": "CRLAIN0123T86510522I2005",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS01500",
				"callType": "FS",
				"productReferenceId": "246863",
				"productName": "CROMA INV/AC 1T CRLAIN0123T8651 3S",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS112210",
				"dateOfPurchase": "2022-12-16T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 3.2,
				"servifyCallClosureDateTime": "0013-02-12T13:06:00.000+00:00",
				"servifySerialNumber": "CRLAIN0123T86510522I2005",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 4,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS01405",
				"callType": "FS",
				"productReferenceId": "246841",
				"productName": "CROMA 3L Instant Geyser CRLH003GYA254201",
				"warranty": "IW",
				"productCategory": "Geyser",
				"productSubCategory": "Instant Geysers",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166007457664",
				"dateOfPurchase": "2023-01-05T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 1.21,
				"servifyCallClosureDateTime": "0035-02-13T13:13:00.000+00:00",
				"servifySerialNumber": "CRLH003GYA254201W082202193",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Hot Water",
				"l2": "Loose Wires",
				"l3": "Wiring Adjustment/ Refitted#",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210823ID60842",
				"callType": "ID",
				"productReferenceId": "246171",
				"productName": "Croma INV W/M FL 8.5kg CRLWFL0755W7904",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4271107",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 0.85,
				"servifyCallClosureDateTime": "0028-02-13T12:52:00.000+00:00",
				"servifySerialNumber": "CRLWFL0855W7904062300196",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823FS02889",
				"callType": "FS",
				"productReferenceId": "246171",
				"productName": "Croma INV W/M FL 8.5kg CRLWFL0755W7904",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4271107",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 1.02,
				"servifyCallClosureDateTime": "0034-02-13T14:18:00.000+00:00",
				"servifySerialNumber": "CRLWFL0855W7904062300196",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 5,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC160823ID67005",
				"callType": "ID",
				"productReferenceId": "246170",
				"productName": "Croma INV W/M FL 7.5kg CRLWFL0755W7903",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA4066061",
				"dateOfPurchase": "2023-08-10T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-15T18:30:00.000+00:00",
				"tat": 0.13,
				"servifyCallClosureDateTime": "0022-02-13T12:08:00.000+00:00",
				"servifySerialNumber": "CRLWFL0755W7903062300060",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS02489",
				"callType": "FS",
				"productReferenceId": "246170",
				"productName": "Croma INV W/M FL 7.5kg CRLWFL0755W7903",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9760131",
				"dateOfPurchase": "2022-11-10T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 2.97,
				"servifyCallClosureDateTime": "0037-02-12T10:46:00.000+00:00",
				"servifySerialNumber": "CRLWFL0755W7903072200056",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC190823ID56605",
				"callType": "ID",
				"productReferenceId": "246170",
				"productName": "Croma INV W/M FL 7.5kg CRLWFL0755W7903",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166353358837",
				"dateOfPurchase": "2023-08-18T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.95,
				"servifyCallClosureDateTime": "0026-02-13T13:51:00.000+00:00",
				"servifySerialNumber": "CRLWFL0755W7903062300042",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS01993",
				"callType": "FS",
				"productReferenceId": "246170",
				"productName": "Croma INV W/M FL 7.5kg CRLWFL0755W7903",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS9760131",
				"dateOfPurchase": "2022-11-10T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 3.03,
				"servifyCallClosureDateTime": "0037-02-12T10:47:00.000+00:00",
				"servifySerialNumber": "CRLWFL0755W7903072200156",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD080823FS00758",
				"callType": "FS",
				"productReferenceId": "246169",
				"productName": "Croma W/M FL 7kg CRLW070FLF017902",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166009603530",
				"dateOfPurchase": "2023-04-05T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 1.26,
				"servifyCallClosureDateTime": "0015-02-13T12:56:00.000+00:00",
				"servifySerialNumber": "CRLW070FLF017902012300147",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD090823FS01344",
				"callType": "FS",
				"productReferenceId": "246169",
				"productName": "Croma W/M FL 7kg CRLW070FLF017902",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS8784365",
				"dateOfPurchase": "2022-10-02T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-08T18:30:00.000+00:00",
				"tat": 0.23,
				"servifyCallClosureDateTime": "0015-02-13T13:25:00.000+00:00",
				"servifySerialNumber": "CRLW070FLF017902062200156",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD120823FS01475",
				"callType": "FS",
				"productReferenceId": "246169",
				"productName": "Croma W/M FL 7kg CRLW070FLF017902",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS350068",
				"dateOfPurchase": "2022-12-28T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 2.11,
				"servifyCallClosureDateTime": "0020-02-13T10:15:00.000+00:00",
				"servifySerialNumber": "CRLW070FLF017902082200251",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210823ID47173",
				"callType": "ID",
				"productReferenceId": "246168",
				"productName": "Croma W/M FL 6kg CRLWFL0605W7901",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4241782",
				"dateOfPurchase": "2023-08-16T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 0.04,
				"servifyCallClosureDateTime": "0027-02-13T13:03:00.000+00:00",
				"servifySerialNumber": "CRLWFL0605W7901062300038",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS02809",
				"callType": "FS",
				"productReferenceId": "246150",
				"productName": "CROMA SBS 563L CRAR2651",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Side By Side",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS733633",
				"dateOfPurchase": "2023-03-07T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 3.04,
				"servifyCallClosureDateTime": "0031-02-13T12:37:00.000+00:00",
				"servifySerialNumber": "CRAR2651122200054",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Compressor Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC190823ID45822",
				"callType": "ID",
				"productReferenceId": "246150",
				"productName": "CROMA SBS 563L CRAR2651",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Side By Side",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4239347",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.9,
				"servifyCallClosureDateTime": "0026-02-13T13:41:00.000+00:00",
				"servifySerialNumber": "CRAR2651072300380",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS02245",
				"callType": "FS",
				"productReferenceId": "245079",
				"productName": "E CROMA REF DC 190L 2S CRLRFC404sD190",
				"warranty": "IW",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Direct Cool <300L",
				"oss": "Sealed System",
				"salesInvoiceNumber": "CRE004SLE004AZ9864587",
				"dateOfPurchase": "2022-11-17T18:30:00.000+00:00",
				"pincode": 361230,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.05,
				"servifyCallClosureDateTime": "0028-02-13T11:30:00.000+00:00",
				"servifySerialNumber": "CRLRFC404SD190082201280",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Compressor Noise",
				"l2": "Compressor Jam",
				"l3": "Compressor Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC310723ID86489",
				"callType": "ID",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC3785386",
				"dateOfPurchase": "2023-07-24T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 0.32,
				"servifyCallClosureDateTime": "0030-02-13T14:49:00.000+00:00",
				"servifySerialNumber": "CRLWMD702STL75W072303412",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD130823FS00153",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC3633412",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361003,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 3.07,
				"servifyCallClosureDateTime": "0022-02-13T06:20:00.000+00:00",
				"servifySerialNumber": "CRLWMD702sTL75W062301643",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS00560",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC3613916",
				"dateOfPurchase": "2023-07-14T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 0.27,
				"servifyCallClosureDateTime": "0007-02-13T12:20:00.000+00:00",
				"servifySerialNumber": "CRLWMD702STL75W062301939",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823FS01043",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC2568119",
				"dateOfPurchase": "2023-05-27T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 2.12,
				"servifyCallClosureDateTime": "0035-02-13T10:41:00.000+00:00",
				"servifySerialNumber": "CRLWMD702sTL75W052300856",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS03356",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLE004TC7182488",
				"dateOfPurchase": "2022-07-20T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 2.84,
				"servifyCallClosureDateTime": "0020-02-13T09:50:00.000+00:00",
				"servifySerialNumber": "2.44E+14",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS00720",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLE001FK5147393",
				"dateOfPurchase": "2022-04-28T18:30:00.000+00:00",
				"pincode": 361305,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 0.16,
				"servifyCallClosureDateTime": "0007-02-13T10:22:00.000+00:00",
				"servifySerialNumber": "2.44E+14",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Wash Not Working",
				"l2": "Wash Motor Stuck (Rotor Jam/Coil Open)",
				"l3": "Wash Motor Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS01633",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4492816",
				"dateOfPurchase": "2022-03-26T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 2.11,
				"servifyCallClosureDateTime": "0013-02-12T11:01:00.000+00:00",
				"servifySerialNumber": "2.44E+14",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD150823FS02481",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA036RS4188270",
				"dateOfPurchase": "2022-03-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-14T18:30:00.000+00:00",
				"tat": 0.79,
				"servifyCallClosureDateTime": "0022-02-13T07:38:00.000+00:00",
				"servifySerialNumber": "2.44E+14",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD150823FS02683",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRE004SLTC3633412",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361003,
				"servifyCallCreationDateTime": "2023-08-14T18:30:00.000+00:00",
				"tat": 1.99,
				"servifyCallClosureDateTime": "0023-02-13T12:51:00.000+00:00",
				"servifySerialNumber": "CRLWMD702sTL75W053301643",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS03394",
				"callType": "FS",
				"productReferenceId": "243594",
				"productName": "E-CROMA W/M TL 7.5KG FA CRLWMD702STL75",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRE001SLE001EC7068985",
				"dateOfPurchase": "2022-07-14T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0008-02-13T14:02:00.000+00:00",
				"servifySerialNumber": "2.45E+14",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Power",
				"l2": "PCB Defective",
				"l3": "PCB Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS00556",
				"callType": "FS",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS54929",
				"dateOfPurchase": "2023-01-26T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.2,
				"servifyCallClosureDateTime": "0035-02-13T10:41:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1012332259",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC110823ID44695",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3985375",
				"dateOfPurchase": "2023-08-06T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 0.65,
				"servifyCallClosureDateTime": "0018-02-13T07:42:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333590",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC020823ID08214",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3876982",
				"dateOfPurchase": "2023-07-31T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.75,
				"servifyCallClosureDateTime": "0009-02-12T07:34:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333466",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310823FS00305",
				"callType": "FS",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3876982",
				"dateOfPurchase": "2023-07-31T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-30T18:30:00.000+00:00",
				"tat": 0.04,
				"servifyCallClosureDateTime": "0037-02-12T06:41:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333466",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 28,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC020823ID07736",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3875883",
				"dateOfPurchase": "2023-07-31T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.85,
				"servifyCallClosureDateTime": "0009-02-12T09:54:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333459",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC290723ID51957",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3810894",
				"dateOfPurchase": "2023-07-27T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.18,
				"servifyCallClosureDateTime": "0025-02-12T10:54:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333593",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS04207",
				"callType": "FS",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3801023",
				"dateOfPurchase": "2023-07-26T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 0.71,
				"servifyCallClosureDateTime": "0007-02-13T07:54:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333601",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound",
				"l2": "No Signal From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS03177",
				"callType": "FS",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS269252",
				"dateOfPurchase": "2023-02-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.03,
				"servifyCallClosureDateTime": "0008-02-13T13:34:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1012331101",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Defective",
				"l2": "Battery Contact Damage",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC290823ID86286",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA4326060",
				"dateOfPurchase": "2023-08-22T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.1,
				"servifyCallClosureDateTime": "0035-02-13T09:55:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333691",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD230823EW00789",
				"callType": "EW",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA345002260029",
				"dateOfPurchase": "2022-06-09T18:30:00.000+00:00",
				"pincode": 361305,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 5.88,
				"servifyCallClosureDateTime": "0036-02-13T12:20:00.000+00:00",
				"servifySerialNumber": "CREL7364022213190",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS03590",
				"callType": "FS",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS1188852",
				"dateOfPurchase": "2022-11-30T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 0.72,
				"servifyCallClosureDateTime": "0018-02-13T07:54:00.000+00:00",
				"servifySerialNumber": "CREL7368Y1082208691",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Not Working",
				"l2": "No Commands From Mike",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC140823ID95095",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166353148963",
				"dateOfPurchase": "2023-08-13T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.87,
				"servifyCallClosureDateTime": "0021-02-12T10:08:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333471",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC020823ID11257",
				"callType": "ID",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166352796054",
				"dateOfPurchase": "2023-08-01T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 0.94,
				"servifyCallClosureDateTime": "0009-02-12T11:04:00.000+00:00",
				"servifySerialNumber": "CREL7364Y1072333602",
				"technician": "Afzal Sama",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823EW01211",
				"callType": "EW",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166002044017",
				"dateOfPurchase": "2022-05-29T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 3.74,
				"servifyCallClosureDateTime": "0017-02-12T07:32:00.000+00:00",
				"servifySerialNumber": "CREL7364032215995",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823RF00054",
				"callType": "RF",
				"productReferenceId": "242944",
				"productName": "CROMA LED HDR FIRETV 32IN EL7364 1YRWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-11-30T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 3.06,
				"servifyCallClosureDateTime": "0033-02-12T08:03:00.000+00:00",
				"servifySerialNumber": "CREL032HGC024601T062303444",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Not Working",
				"l2": "No Commands From Mike",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC310723ID98615",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3852278",
				"dateOfPurchase": "2023-07-29T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 0.84,
				"servifyCallClosureDateTime": "0007-02-13T10:50:00.000+00:00",
				"servifySerialNumber": "CREL7369072350626",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC310723ID98610",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3852275",
				"dateOfPurchase": "2023-07-28T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 0.72,
				"servifyCallClosureDateTime": "0007-02-13T07:47:00.000+00:00",
				"servifySerialNumber": "CREL7369072350567",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC030823ID13671",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3303690",
				"dateOfPurchase": "2023-08-02T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-02T18:30:00.000+00:00",
				"tat": 0.1,
				"servifyCallClosureDateTime": "0009-02-12T09:20:00.000+00:00",
				"servifySerialNumber": "CREL7369052343387",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC020623ID13043",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEC2607418",
				"dateOfPurchase": "2023-05-27T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 0.59,
				"servifyCallClosureDateTime": "0034-02-13T08:08:00.000+00:00",
				"servifySerialNumber": "CREL7369052345383",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210723ID44840",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3645120",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:44:00.000+00:00",
				"servifySerialNumber": "CREL7369072349050",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210723ID44829",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3645093",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361335,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:43:00.000+00:00",
				"servifySerialNumber": "CREL7369072348505",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210723ID44854",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3645053",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:48:00.000+00:00",
				"servifySerialNumber": "CREL7369072348542",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210723ID44849",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3645042",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:46:00.000+00:00",
				"servifySerialNumber": "CREL7369072349118",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210723ID44867",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3645012",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:45:00.000+00:00",
				"servifySerialNumber": "CREL7369072349137",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC210723ID44872",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA3644971",
				"dateOfPurchase": "2023-07-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-07T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0014-02-13T07:47:00.000+00:00",
				"servifySerialNumber": "CREL7369072349128",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS01761",
				"callType": "FS",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Panel Replaced",
				"salesInvoiceNumber": "SLA166RS8748758",
				"dateOfPurchase": "2022-09-27T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 6.27,
				"servifyCallClosureDateTime": "0017-02-12T14:54:00.000+00:00",
				"servifySerialNumber": "CREL7367Y072206655",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Picture",
				"l2": "Panel Defective",
				"l3": "Panel Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250723EW00456",
				"callType": "EW",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Panel Replaced",
				"salesInvoiceNumber": "SLA166RS3407537",
				"dateOfPurchase": "2021-12-27T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-07-26T18:30:00.000+00:00",
				"tat": 4.91,
				"servifyCallClosureDateTime": "0007-02-13T13:25:00.000+00:00",
				"servifySerialNumber": "CREL7369122113262",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Picture",
				"l2": "Panel Defective",
				"l3": "Panel Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC060823ID32837",
				"callType": "ID",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166352896480",
				"dateOfPurchase": "2023-08-05T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 0.97,
				"servifyCallClosureDateTime": "0013-02-12T10:55:00.000+00:00",
				"servifySerialNumber": "CREL7369072350239",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Installation & Demo",
				"l2": "Demo/Installation Of New Product",
				"l3": "Demo/Installation Of New Product #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823RF00089",
				"callType": "RF",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166010022910",
				"dateOfPurchase": "2021-10-20T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 5.15,
				"servifyCallClosureDateTime": "0013-02-12T14:14:00.000+00:00",
				"servifySerialNumber": "CREL7369102106119",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280723RF00145",
				"callType": "RF",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CR",
				"dateOfPurchase": "2022-09-27T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-27T18:30:00.000+00:00",
				"tat": 3.82,
				"servifyCallClosureDateTime": "0007-02-13T05:43:00.000+00:00",
				"servifySerialNumber": "CREL7369012219055",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS02796",
				"callType": "FS",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA345SLA345007004341",
				"dateOfPurchase": "2022-12-19T18:30:00.000+00:00",
				"pincode": 361325,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 1.03,
				"servifyCallClosureDateTime": "0028-02-13T11:52:00.000+00:00",
				"servifySerialNumber": "CREL7369092210030",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Sound, Set Dead",
				"l2": "Failure In Main Board",
				"l3": "Main Board Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823FS01671",
				"callType": "FS",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA345SLA345004668445",
				"dateOfPurchase": "2022-09-21T18:30:00.000+00:00",
				"pincode": 360575,
				"servifyCallCreationDateTime": "2023-08-20T18:30:00.000+00:00",
				"tat": 3.98,
				"servifyCallClosureDateTime": "0031-02-13T08:08:00.000+00:00",
				"servifySerialNumber": "CREL7369082298002",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Main Board",
				"l3": "Main Board Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823EW01102",
				"callType": "EW",
				"productReferenceId": "242597",
				"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166003131625",
				"dateOfPurchase": "2022-07-19T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 0.72,
				"servifyCallClosureDateTime": "0014-02-13T09:05:00.000+00:00",
				"servifySerialNumber": "CREL7369062270861",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD090823FS03162",
				"callType": "FS",
				"productReferenceId": "233702",
				"productName": "CROMA LED FHD ANDROID 43IN EL7371 3YWRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020024518",
				"dateOfPurchase": "2021-05-30T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-08T18:30:00.000+00:00",
				"tat": 5.0,
				"servifyCallClosureDateTime": "0020-02-13T13:09:00.000+00:00",
				"servifySerialNumber": "CREL7371042100412",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD100823EW00632",
				"callType": "EW",
				"productReferenceId": "232215",
				"productName": "Croma D/Cooler 75L CRRC1206",
				"warranty": "IW",
				"productCategory": "Air Cooler",
				"productSubCategory": "Room Coolers",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020023703",
				"dateOfPurchase": "2021-04-12T18:30:00.000+00:00",
				"pincode": 360530,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 2.25,
				"servifyCallClosureDateTime": "0019-02-13T13:08:00.000+00:00",
				"servifySerialNumber": "CRRC1206032100022",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 210.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 210.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD060823FS00418",
				"callType": "FS",
				"productReferenceId": "232036",
				"productName": "CROMA W/M Semi 9.0kg CRAW2253",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4542744",
				"dateOfPurchase": "2022-03-28T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 2.98,
				"servifyCallClosureDateTime": "0015-02-13T05:16:00.000+00:00",
				"servifySerialNumber": "CRAW2253022200618",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD230823FS00877",
				"callType": "FS",
				"productReferenceId": "232036",
				"productName": "CROMA W/M Semi 9.0kg CRAW2253",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166000556353",
				"dateOfPurchase": "2022-03-20T18:30:00.000+00:00",
				"pincode": 361230,
				"servifyCallCreationDateTime": "2023-08-22T18:30:00.000+00:00",
				"tat": 1.34,
				"servifyCallClosureDateTime": "0030-02-13T14:50:00.000+00:00",
				"servifySerialNumber": "CRAW2253022201000",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS04153",
				"callType": "FS",
				"productReferenceId": "231504",
				"productName": "Croma Led UHD FireTv 50in El7367 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLE001EA7678247",
				"dateOfPurchase": "2022-08-09T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 3.95,
				"servifyCallClosureDateTime": "0010-02-13T13:41:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1052205151",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS02598",
				"callType": "FS",
				"productReferenceId": "231504",
				"productName": "Croma Led UHD FireTv 50in El7367 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLE001EA7678247",
				"dateOfPurchase": "2022-08-09T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.01,
				"servifyCallClosureDateTime": "0023-02-13T12:56:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1052205151",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Backlight",
				"l2": "Backlight Defective",
				"l3": "Backlight Replaced $",
				"repeatInDays": 16,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS03195",
				"callType": "FS",
				"productReferenceId": "231504",
				"productName": "Croma Led UHD FireTv 50in El7367 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Panel Replaced",
				"salesInvoiceNumber": "SLA166002262140",
				"dateOfPurchase": "2022-06-09T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 3.99,
				"servifyCallClosureDateTime": "0011-02-13T12:53:00.000+00:00",
				"servifySerialNumber": "CREL7367Y1052204745",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Picture",
				"l2": "Panel Defective",
				"l3": "Panel Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 855.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 855.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS00765",
				"callType": "FS",
				"productReferenceId": "231503",
				"productName": "Croma Led UHD FireTv 43in El7366 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS1821332",
				"dateOfPurchase": "2021-06-07T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 0.29,
				"servifyCallClosureDateTime": "0035-02-13T13:08:00.000+00:00",
				"servifySerialNumber": "G7X1H70011130089",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD100823FS02977",
				"callType": "FS",
				"productReferenceId": "231503",
				"productName": "Croma Led UHD FireTv 43in El7366 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS4670301",
				"dateOfPurchase": "2022-04-03T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-09T18:30:00.000+00:00",
				"tat": 3.97,
				"servifyCallClosureDateTime": "0020-02-13T12:03:00.000+00:00",
				"servifySerialNumber": "CREL7365Y10822125338",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Defective",
				"l2": "Remote LED Not Working",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823FS03610",
				"callType": "FS",
				"productReferenceId": "231502",
				"productName": "Croma Led FHD FireTv 43in El7365 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS2780126",
				"dateOfPurchase": "2021-10-31T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 2.96,
				"servifyCallClosureDateTime": "0020-02-13T13:48:00.000+00:00",
				"servifySerialNumber": "CREL7365092100869",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Not Working",
				"l2": "No Commands From Mike",
				"l3": "Remote Control Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823FS02667",
				"callType": "FS",
				"productReferenceId": "231502",
				"productName": "Croma Led FHD FireTv 43in El7365 3yrwrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS1944556",
				"dateOfPurchase": "2021-07-08T18:30:00.000+00:00",
				"pincode": 361230,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 1.89,
				"servifyCallClosureDateTime": "0035-02-13T10:39:00.000+00:00",
				"servifySerialNumber": "G7X1C9001097006C",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Factory Reset Done #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823EW00820",
				"callType": "EW",
				"productReferenceId": "231488",
				"productName": "CROMA INV/AC 1.5T CRAC7706 3S PAC",
				"warranty": "IW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS4083249",
				"dateOfPurchase": "2022-02-22T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 0.06,
				"servifyCallClosureDateTime": "0034-02-13T14:15:00.000+00:00",
				"servifySerialNumber": "CRAC77061121I5877",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Power On",
				"l2": "ODU PCB Mal Function",
				"l3": "ODU PCB Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS02698",
				"callType": "FS",
				"productReferenceId": "231488",
				"productName": "CROMA INV/AC 1.5T CRAC7706 3S PAC",
				"warranty": "OW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166002836628",
				"dateOfPurchase": "2022-07-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 1.14,
				"servifyCallClosureDateTime": "0007-02-13T14:23:00.000+00:00",
				"servifySerialNumber": "CRAC77060921I4254",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS03280",
				"callType": "FS",
				"productReferenceId": "227259",
				"productName": "CROMA W/M TL 7KG FA CRAW1403",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS11042",
				"dateOfPurchase": "2023-01-24T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 1.85,
				"servifyCallClosureDateTime": "0032-02-13T09:39:00.000+00:00",
				"servifySerialNumber": "CRAW1403072201090",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS00533",
				"callType": "FS",
				"productReferenceId": "227259",
				"productName": "CROMA W/M TL 7KG FA CRAW1403",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS9177238",
				"dateOfPurchase": "2022-10-18T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 0.27,
				"servifyCallClosureDateTime": "0011-02-13T12:26:00.000+00:00",
				"servifySerialNumber": "CRAW1403062200958",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS00474",
				"callType": "FS",
				"productReferenceId": "227259",
				"productName": "CROMA W/M TL 7KG FA CRAW1403",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS9177238",
				"dateOfPurchase": "2022-10-18T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 2.33,
				"servifyCallClosureDateTime": "0013-02-12T13:39:00.000+00:00",
				"servifySerialNumber": "CRAW1403062300958",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS00473",
				"callType": "FS",
				"productReferenceId": "227259",
				"productName": "CROMA W/M TL 7KG FA CRAW1403",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS9177238",
				"dateOfPurchase": "2022-10-18T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 2.33,
				"servifyCallClosureDateTime": "0013-02-12T13:40:00.000+00:00",
				"servifySerialNumber": "CRAW1403052200958",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS00988",
				"callType": "FS",
				"productReferenceId": "225372",
				"productName": "CROMA LED HDR SMT 32INCH EL7363 3YR WRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020022559",
				"dateOfPurchase": "2021-03-04T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 1.95,
				"servifyCallClosureDateTime": "0012-02-13T05:46:00.000+00:00",
				"servifySerialNumber": "CREL7363122009557",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Software Update Done $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD060823FS02664",
				"callType": "FS",
				"productReferenceId": "225372",
				"productName": "CROMA LED HDR SMT 32INCH EL7363 3YR WRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020021606",
				"dateOfPurchase": "2021-01-25T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 3.0,
				"servifyCallClosureDateTime": "0015-02-13T12:05:00.000+00:00",
				"servifySerialNumber": "CREL7363122008939",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Backlight",
				"l2": "Backlight Connector Loose",
				"l3": "Connector Refixed #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS02309",
				"callType": "FS",
				"productReferenceId": "225372",
				"productName": "CROMA LED HDR SMT 32INCH EL7363 3YR WRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020019559",
				"dateOfPurchase": "2020-11-13T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.07,
				"servifyCallClosureDateTime": "0028-02-13T11:58:00.000+00:00",
				"servifySerialNumber": "CREL7363102006870",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Set Dead",
				"l2": "Failure In Main Board",
				"l3": "Main Board Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS02592",
				"callType": "FS",
				"productReferenceId": "225372",
				"productName": "CROMA LED HDR SMT 32INCH EL7363 3YR WRTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166010013812",
				"dateOfPurchase": "2020-10-20T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0023-02-13T13:02:00.000+00:00",
				"servifySerialNumber": "CREL7363092003933",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Set Dead",
				"l2": "Failure In Main Board",
				"l3": "Main Board Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS01018",
				"callType": "FS",
				"productReferenceId": "224102",
				"productName": "CROMA LED HDR SMT 39.5INCH EL7362 3YR WR",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166010013179",
				"dateOfPurchase": "2020-09-23T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 1.04,
				"servifyCallClosureDateTime": "0011-02-13T08:01:00.000+00:00",
				"servifySerialNumber": "CREL7362082002916",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Software Update Done $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS02793",
				"callType": "FS",
				"productReferenceId": "223919",
				"productName": "Croma 2.1 180W sound bar CRES1099",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "Sound Bars_H",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS3119591",
				"dateOfPurchase": "2023-06-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 2.93,
				"servifyCallClosureDateTime": "0009-02-12T09:34:00.000+00:00",
				"servifySerialNumber": "CRES1099112200396",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD090823FS03284",
				"callType": "FS",
				"productReferenceId": "223919",
				"productName": "Croma 2.1 180W sound bar CRES1099",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "Sound Bars_H",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS3119591",
				"dateOfPurchase": "2023-06-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-08T18:30:00.000+00:00",
				"tat": 1.7,
				"servifyCallClosureDateTime": "0017-02-12T06:24:00.000+00:00",
				"servifySerialNumber": "CRES1099112200396",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 9,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS03189",
				"callType": "FS",
				"productReferenceId": "223919",
				"productName": "Croma 2.1 180W sound bar CRES1099",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "Sound Bars_H",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS3119591",
				"dateOfPurchase": "2023-06-15T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 2.88,
				"servifyCallClosureDateTime": "0009-02-12T09:35:00.000+00:00",
				"servifySerialNumber": "CRES1099112100396",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "BAC260823FS02101",
				"callType": "FS",
				"productReferenceId": "223917",
				"productName": "Croma 2.1 240W sound bar CRES1098",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "Sound Bars_L",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS1283826",
				"dateOfPurchase": "2022-12-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0032-02-13T10:12:00.000+00:00",
				"servifySerialNumber": "CRES1098072200356",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Bass Not Working",
				"l2": "Failure In Main Board",
				"l3": "Main Board Repaired $",
				"repeatInDays": 16,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "BAC090823FS03453",
				"callType": "FS",
				"productReferenceId": "223917",
				"productName": "Croma 2.1 240W sound bar CRES1098",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "Sound Bars_L",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS1283826",
				"dateOfPurchase": "2022-12-06T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-08T18:30:00.000+00:00",
				"tat": 3.95,
				"servifyCallClosureDateTime": "0019-02-13T12:57:00.000+00:00",
				"servifySerialNumber": "CRES1098072200356",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Remote Not Working",
				"l2": "Pairing Not Done",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "BAC040823FS01914",
				"callType": "FS",
				"productReferenceId": "223917",
				"productName": "Croma 2.1 240W sound bar CRES1098",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "Sound Bars_L",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS563194",
				"dateOfPurchase": "2023-01-09T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 3.16,
				"servifyCallClosureDateTime": "0013-02-12T13:08:00.000+00:00",
				"servifySerialNumber": "CRES1098072201037",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD170823FS03039",
				"callType": "FS",
				"productReferenceId": "223893",
				"productName": "Croma T/Cooler 47L Polar CRRC1205",
				"warranty": "IW",
				"productCategory": "Air Cooler",
				"productSubCategory": "Room Coolers",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS4334803",
				"dateOfPurchase": "2022-03-15T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-16T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0023-02-13T13:54:00.000+00:00",
				"servifySerialNumber": "CRRC1205022201278",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 250.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 250.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290723FS00842",
				"callType": "FS",
				"productReferenceId": "222845",
				"productName": "CROMA LED FHD SMART 43INCH EL7361 3YRWTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020016955",
				"dateOfPurchase": "2020-09-04T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-28T18:30:00.000+00:00",
				"tat": 2.98,
				"servifyCallClosureDateTime": "0007-02-13T06:15:00.000+00:00",
				"servifySerialNumber": "CREL7361092001820",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 500.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 500.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823EW01009",
				"callType": "EW",
				"productReferenceId": "222845",
				"productName": "CROMA LED FHD SMART 43INCH EL7361 3YRWTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020015276",
				"dateOfPurchase": "2020-07-22T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 5.8,
				"servifyCallClosureDateTime": "0034-02-13T07:49:00.000+00:00",
				"servifySerialNumber": "CREL7361032000066",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 400.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 400.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS00491",
				"callType": "FS",
				"productReferenceId": "222241",
				"productName": "CROMA INV/AC 1T CRAC7886 3S PAC",
				"warranty": "OW",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020015257",
				"dateOfPurchase": "2020-07-22T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 1.32,
				"servifyCallClosureDateTime": "0029-02-12T13:25:00.000+00:00",
				"servifySerialNumber": "CRAC78860120I1015",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823FS02314",
				"callType": "FS",
				"productReferenceId": "220804",
				"productName": "Croma Party Machine CREY3027",
				"warranty": "IW",
				"productCategory": "Home Theatre",
				"productSubCategory": "DJ MACHINE_H",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166352969948",
				"dateOfPurchase": "2023-08-08T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.01,
				"servifyCallClosureDateTime": "0032-02-13T10:11:00.000+00:00",
				"servifySerialNumber": "CREY302701220181",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Main Board",
				"l3": "Main Board Repaired $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823EW00754",
				"callType": "EW",
				"productReferenceId": "219224",
				"productName": "CROMA LED UHD Smt 55inch EL7338 3Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > =50\" & <=55\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020010674",
				"dateOfPurchase": "2020-01-25T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 3.77,
				"servifyCallClosureDateTime": "0037-02-12T06:28:00.000+00:00",
				"servifySerialNumber": "CREL7338071900172",
				"technician": "Khodidas Gondaliya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "-",
				"l2": "-",
				"l3": "-",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823EW00846",
				"callType": "EW",
				"productReferenceId": "219223",
				"productName": "CROMA LED UHD Smt 49in EL7346 3Yr Wrty",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166010007866",
				"dateOfPurchase": "2019-11-11T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 0.21,
				"servifyCallClosureDateTime": "0034-02-13T10:55:00.000+00:00",
				"servifySerialNumber": "CREL7346101900680",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 400.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 400.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD120823FS00539",
				"callType": "FS",
				"productReferenceId": "219095",
				"productName": "CROMA CREL7318 32 basic LED(3 yrs wrnty)",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED =32\"",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166010012517",
				"dateOfPurchase": "2020-08-19T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 4.0,
				"servifyCallClosureDateTime": "0022-02-13T05:44:00.000+00:00",
				"servifySerialNumber": "CREL7318121909893",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Set Dead",
				"l2": "Failure In Main Board",
				"l3": "Main Board Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD140823EW00051",
				"callType": "EW",
				"productReferenceId": "219048",
				"productName": "CROMA LED FHD SMART 43INCH EL7345 3YRWTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020010556",
				"dateOfPurchase": "2020-01-24T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 3.41,
				"servifyCallClosureDateTime": "0023-02-13T14:49:00.000+00:00",
				"servifySerialNumber": "CREL7345012000097",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Nosie From Input Device",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 400.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 400.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823EW01270",
				"callType": "EW",
				"productReferenceId": "219048",
				"productName": "CROMA LED FHD SMART 43INCH EL7345 3YRWTY",
				"warranty": "IW",
				"productCategory": "Televisions",
				"productSubCategory": "LED > 32\" & <50\"",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020010507",
				"dateOfPurchase": "2020-01-22T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 2.6,
				"servifyCallClosureDateTime": "0016-02-13T05:15:00.000+00:00",
				"servifySerialNumber": "CREL7345012000153",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Failure In Software",
				"l3": "Software Update Done $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 400.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 400.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD110823EW00141",
				"callType": "EW",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020021684",
				"dateOfPurchase": "2021-01-27T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-10T18:30:00.000+00:00",
				"tat": 1.34,
				"servifyCallClosureDateTime": "0018-02-13T13:38:00.000+00:00",
				"servifySerialNumber": "CRAW2223122000204",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS00600",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "IW",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRE001SLE001EA9389378",
				"dateOfPurchase": "2022-10-23T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 0.34,
				"servifyCallClosureDateTime": "0034-02-13T14:16:00.000+00:00",
				"servifySerialNumber": "CRAW2223082200008",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS00351",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRE001EA7832828",
				"dateOfPurchase": "2022-05-12T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 0.29,
				"servifyCallClosureDateTime": "0011-02-13T12:24:00.000+00:00",
				"servifySerialNumber": "CRAW2223042200449",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD270823FS00210",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLRS1526282",
				"dateOfPurchase": "2023-04-17T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-26T18:30:00.000+00:00",
				"tat": 0.35,
				"servifyCallClosureDateTime": "0033-02-12T13:39:00.000+00:00",
				"servifySerialNumber": "CRAW2223042300275",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD190823FS03780",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS647033",
				"dateOfPurchase": "2023-01-15T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 0.01,
				"servifyCallClosureDateTime": "0025-02-12T14:20:00.000+00:00",
				"servifySerialNumber": "CRAW2223122201286",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Spin Low Speed",
				"l2": "Spin Motor Stuck",
				"l3": "Spin Motor Replaced $",
				"repeatInDays": 17,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD020823FS02608",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS647033",
				"dateOfPurchase": "2023-01-15T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-01T18:30:00.000+00:00",
				"tat": 2.14,
				"servifyCallClosureDateTime": "0010-02-13T14:46:00.000+00:00",
				"servifySerialNumber": "CRAW2223122201286",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS00711",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS6186428",
				"dateOfPurchase": "2022-06-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 0.24,
				"servifyCallClosureDateTime": "0010-02-13T12:20:00.000+00:00",
				"servifySerialNumber": "CRAW2223052200067",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823EW00713",
				"callType": "EW",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS6134551",
				"dateOfPurchase": "2022-06-09T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 0.35,
				"servifyCallClosureDateTime": "0034-02-13T14:20:00.000+00:00",
				"servifySerialNumber": "CRAW2223052200676",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 22,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS01691",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS6134551",
				"dateOfPurchase": "2022-06-09T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 2.23,
				"servifyCallClosureDateTime": "0013-02-12T14:03:00.000+00:00",
				"servifySerialNumber": "CRAW2223052200676",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS02779",
				"callType": "FS",
				"productReferenceId": "218638",
				"productName": "CROMA W/M Semi 7.5Kg CRAW2223",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS1006927",
				"dateOfPurchase": "2022-11-24T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 3.96,
				"servifyCallClosureDateTime": "0034-02-13T10:59:00.000+00:00",
				"servifySerialNumber": "CRAW2223112200381",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Auto Off (Inbuilt Feature)",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC070823ID41271",
				"callType": "ID",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLTC3881231",
				"dateOfPurchase": "2023-07-30T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 0.1,
				"servifyCallClosureDateTime": "0013-02-12T14:01:00.000+00:00",
				"servifySerialNumber": "CRAW1402072200238",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC200823ID59536",
				"callType": "ID",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4268382",
				"dateOfPurchase": "2023-08-18T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-19T18:30:00.000+00:00",
				"tat": 0.87,
				"servifyCallClosureDateTime": "0027-02-13T10:20:00.000+00:00",
				"servifySerialNumber": "CRAW1402012200353",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC050823ID21771",
				"callType": "ID",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS3925347",
				"dateOfPurchase": "2023-08-03T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 0.9,
				"servifyCallClosureDateTime": "0012-02-13T11:14:00.000+00:00",
				"servifySerialNumber": "CRAW1402082200461",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD030823FS00132",
				"callType": "FS",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS6035866",
				"dateOfPurchase": "2022-06-04T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-02T18:30:00.000+00:00",
				"tat": 1.27,
				"servifyCallClosureDateTime": "0010-02-13T10:54:00.000+00:00",
				"servifySerialNumber": "CRAW1402012200371",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823EW00291",
				"callType": "EW",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRE017SLE017EA1177341",
				"dateOfPurchase": "2019-12-22T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 0.76,
				"servifyCallClosureDateTime": "0033-02-12T07:57:00.000+00:00",
				"servifySerialNumber": "340846307019627D00226",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD140823FS02354",
				"callType": "FS",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS452616",
				"dateOfPurchase": "2023-01-02T18:30:00.000+00:00",
				"pincode": 361120,
				"servifyCallCreationDateTime": "2023-08-13T18:30:00.000+00:00",
				"tat": 0.01,
				"servifyCallClosureDateTime": "0020-02-13T10:37:00.000+00:00",
				"servifySerialNumber": "CRLR185DCC008902T06230013",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD180823EW00602",
				"callType": "EW",
				"productReferenceId": "218129",
				"productName": "Croma W/M TL 8kg CRAW1402",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166020010800",
				"dateOfPurchase": "2020-01-30T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-17T18:30:00.000+00:00",
				"tat": 1.04,
				"servifyCallClosureDateTime": "0025-02-12T09:59:00.000+00:00",
				"servifySerialNumber": "CRAW1402101900042",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC280823ID32088",
				"callType": "ID",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4375716",
				"dateOfPurchase": "2023-08-25T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-30T18:30:00.000+00:00",
				"tat": 0.33,
				"servifyCallClosureDateTime": "0037-02-12T15:06:00.000+00:00",
				"servifySerialNumber": "CRAW1401062300794",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 2,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC280823ID08533",
				"callType": "ID",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4375716",
				"dateOfPurchase": "2023-08-25T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 0.74,
				"servifyCallClosureDateTime": "0035-02-13T07:46:00.000+00:00",
				"servifySerialNumber": "CRAW1401062300794",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC130823ID72549",
				"callType": "ID",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLRS4081660",
				"dateOfPurchase": "2023-08-11T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-12T18:30:00.000+00:00",
				"tat": 0.86,
				"servifyCallClosureDateTime": "0020-02-13T13:14:00.000+00:00",
				"servifySerialNumber": "CRAW1401062300747",
				"technician": "Sameer Godhaviya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "SFC220823ID33807",
				"callType": "ID",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLEA4212012",
				"dateOfPurchase": "2023-08-15T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.53,
				"servifyCallClosureDateTime": "0029-02-12T06:17:00.000+00:00",
				"servifySerialNumber": "CRAW1401062300583",
				"technician": "Madhav",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD230823FS03224",
				"callType": "FS",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS2990310",
				"dateOfPurchase": "2021-11-14T18:30:00.000+00:00",
				"pincode": 361003,
				"servifyCallCreationDateTime": "2023-08-22T18:30:00.000+00:00",
				"tat": 2.13,
				"servifyCallClosureDateTime": "0031-02-13T16:05:00.000+00:00",
				"servifySerialNumber": "CRAW1401082100168",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Water Drain",
				"l2": "Drain Lever Broken",
				"l3": "Drain Lever Replaced $",
				"repeatInDays": 16,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823FS02982",
				"callType": "FS",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS2990310",
				"dateOfPurchase": "2021-11-14T18:30:00.000+00:00",
				"pincode": 361003,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 0.81,
				"servifyCallClosureDateTime": "0014-02-13T07:40:00.000+00:00",
				"servifySerialNumber": "CRAW1401082100168",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 1,
				"isRepeat": 1,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD060823FS01209",
				"callType": "FS",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS2990310",
				"dateOfPurchase": "2021-11-14T18:30:00.000+00:00",
				"pincode": 361003,
				"servifyCallCreationDateTime": "2023-08-05T18:30:00.000+00:00",
				"tat": 1.05,
				"servifyCallClosureDateTime": "0013-02-12T08:50:00.000+00:00",
				"servifySerialNumber": "CRAW1401082100168",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD240823FS03081",
				"callType": "FS",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166RS1134233",
				"dateOfPurchase": "2022-11-27T18:30:00.000+00:00",
				"pincode": 361110,
				"servifyCallCreationDateTime": "2023-08-23T18:30:00.000+00:00",
				"tat": 2.04,
				"servifyCallClosureDateTime": "0032-02-13T13:48:00.000+00:00",
				"servifySerialNumber": "CRAW1401072200258",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD290823FS01458",
				"callType": "FS",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS8411329",
				"dateOfPurchase": "2022-09-15T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-28T18:30:00.000+00:00",
				"tat": 2.11,
				"servifyCallClosureDateTime": "0037-02-12T10:41:00.000+00:00",
				"servifySerialNumber": "CRAW1401062200684",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD250823EW00168",
				"callType": "EW",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166020021642",
				"dateOfPurchase": "2021-01-25T18:30:00.000+00:00",
				"pincode": 361006,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 4.18,
				"servifyCallClosureDateTime": "0035-02-13T10:47:00.000+00:00",
				"servifySerialNumber": "CRAW14010920000198",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD280823FS02665",
				"callType": "FS",
				"productReferenceId": "218128",
				"productName": "Croma W/M TL 7kg CRAW1401",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166020008879",
				"dateOfPurchase": "2019-11-13T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-27T18:30:00.000+00:00",
				"tat": 2.01,
				"servifyCallClosureDateTime": "0036-02-13T12:13:00.000+00:00",
				"servifySerialNumber": "8408463140196271D00363",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823EW00142",
				"callType": "EW",
				"productReferenceId": "218127",
				"productName": "Croma W/M FL 6kg CRAW0151",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Front Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166010016211",
				"dateOfPurchase": "2021-01-28T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 3.33,
				"servifyCallClosureDateTime": "0013-02-12T13:28:00.000+00:00",
				"servifySerialNumber": "34B40246010B075G00198",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD230823EW00291",
				"callType": "EW",
				"productReferenceId": "217257",
				"productName": "CROMA S/AC 1T CRAC7721 3S",
				"warranty": "Warranty",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "SLE001EA3945313",
				"dateOfPurchase": "2022-02-10T18:30:00.000+00:00",
				"pincode": 361007,
				"servifyCallCreationDateTime": "2023-08-24T18:30:00.000+00:00",
				"tat": 0.68,
				"servifyCallClosureDateTime": "0032-02-13T09:40:00.000+00:00",
				"servifySerialNumber": "CRAC77211121I3792",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "Compressor Noise",
				"l3": "Compressor Leg Adjusted #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD220823FS02477",
				"callType": "FS",
				"productReferenceId": "217112",
				"productName": "CROMA S/AC 1.5T CRAC7722 3S",
				"warranty": "Out Of Warranty",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Sealed System",
				"salesInvoiceNumber": "CRA166SLA166RS1847990",
				"dateOfPurchase": "2021-06-14T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-21T18:30:00.000+00:00",
				"tat": 0.02,
				"servifyCallClosureDateTime": "0028-02-13T11:15:00.000+00:00",
				"servifySerialNumber": "CRAC77220421I1881",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "No Cooling",
				"l2": "Compressor Dead , Earthling , Terminal Burst/Noise",
				"l3": "Compressor Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD260823EW00701",
				"callType": "EW",
				"productReferenceId": "217112",
				"productName": "CROMA S/AC 1.5T CRAC7722 3S",
				"warranty": "Warranty",
				"productCategory": "Air Conditioner",
				"productSubCategory": "AC Split",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS1821455",
				"dateOfPurchase": "2021-06-07T18:30:00.000+00:00",
				"pincode": 361003,
				"servifyCallCreationDateTime": "2023-08-25T18:30:00.000+00:00",
				"tat": 3.04,
				"servifyCallClosureDateTime": "0035-02-13T13:04:00.000+00:00",
				"servifySerialNumber": "CRAC77220421I12160",
				"technician": "Moin Padhiyar",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD120823FS00650",
				"callType": "FS",
				"productReferenceId": "216438",
				"productName": "CROMA Ref FF 347L 3STR CRAR2404",
				"warranty": "Out Of Warranty",
				"productCategory": "Refrigerator",
				"productSubCategory": "Refrigerator Frost Free >300L",
				"oss": "Sealed System",
				"salesInvoiceNumber": "SLA166020013332",
				"dateOfPurchase": "2020-06-06T18:30:00.000+00:00",
				"pincode": 361002,
				"servifyCallCreationDateTime": "2023-08-11T18:30:00.000+00:00",
				"tat": 2.16,
				"servifyCallClosureDateTime": "0020-02-13T09:57:00.000+00:00",
				"servifySerialNumber": "CRAR240402200038",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Compressor Tripping",
				"l2": "Compressor Low Pumping",
				"l3": "Compressor Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD310723FS02761",
				"callType": "FS",
				"productReferenceId": "215775",
				"productName": "CROMA INV/AC 1.5T CRAC7702 3S",
				"warranty": "Out Of Warranty",
				"productCategory": "Air Conditioner",
				"productSubCategory": "Invertor AC",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020005911",
				"dateOfPurchase": "2019-07-22T18:30:00.000+00:00",
				"pincode": 361004,
				"servifyCallCreationDateTime": "2023-07-30T18:30:00.000+00:00",
				"tat": 2.05,
				"servifyCallClosureDateTime": "0008-02-13T12:16:00.000+00:00",
				"servifySerialNumber": "CRAC7702061901894ID",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD190823EW00912",
				"callType": "EW",
				"productReferenceId": "211393",
				"productName": "CROMA W/M Semi 8.5Kg CRAW2222",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166060000268",
				"dateOfPurchase": "2019-10-24T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-18T18:30:00.000+00:00",
				"tat": 1.95,
				"servifyCallClosureDateTime": "0027-02-13T10:29:00.000+00:00",
				"servifySerialNumber": "CRAW2222041900083",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD040823FS00377",
				"callType": "FS",
				"productReferenceId": "211393",
				"productName": "CROMA W/M Semi 8.5Kg CRAW2222",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020007275",
				"dateOfPurchase": "2019-09-18T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-03T18:30:00.000+00:00",
				"tat": 1.29,
				"servifyCallClosureDateTime": "0011-02-13T12:21:00.000+00:00",
				"servifySerialNumber": "CRAW2222011900035",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD050823FS00961",
				"callType": "FS",
				"productReferenceId": "211393",
				"productName": "CROMA W/M Semi 8.5Kg CRAW2222",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SOA166002642694",
				"dateOfPurchase": "2022-06-27T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-04T18:30:00.000+00:00",
				"tat": 0.26,
				"servifyCallClosureDateTime": "0011-02-13T13:07:00.000+00:00",
				"servifySerialNumber": "CRAW2222052200257",
				"technician": "Sameer Godhaviya",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD100823FS02178",
				"callType": "FS",
				"productReferenceId": "211393",
				"productName": "CROMA W/M Semi 8.5Kg CRAW2222",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Semi Automatic",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166020020924",
				"dateOfPurchase": "2020-12-28T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-09T18:30:00.000+00:00",
				"tat": 3.13,
				"servifyCallClosureDateTime": "0019-02-13T13:04:00.000+00:00",
				"servifySerialNumber": "CRAW2222102000756",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823FS03094",
				"callType": "FS",
				"productReferenceId": "198539",
				"productName": "CROMA W/M TL 7.2KG FA CRAW1301",
				"warranty": "Out Of Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "SLA166020005440",
				"dateOfPurchase": "2019-07-03T18:30:00.000+00:00",
				"pincode": 361008,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 0.81,
				"servifyCallClosureDateTime": "0014-02-13T07:41:00.000+00:00",
				"servifySerialNumber": "CRAW1301041900066",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD210823EW00200",
				"callType": "EW",
				"productReferenceId": "198539",
				"productName": "CROMA W/M TL 7.2KG FA CRAW1301",
				"warranty": "Warranty",
				"productCategory": "Washing Machine",
				"productSubCategory": "WM Top Loaders",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166020020801",
				"dateOfPurchase": "2020-12-23T18:30:00.000+00:00",
				"pincode": 361005,
				"servifyCallCreationDateTime": "2023-08-22T18:30:00.000+00:00",
				"tat": 0.99,
				"servifyCallClosureDateTime": "0030-02-13T14:42:00.000+00:00",
				"servifySerialNumber": "CRAW1301WC80340200002200920A0218",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD070823FS02774",
				"callType": "FS",
				"productReferenceId": "196722",
				"productName": "CROMA W/D3TAP 19LREF CRAK10020",
				"warranty": "Warranty",
				"productCategory": "Water Purifier",
				"productSubCategory": "Water Dispensers",
				"oss": "Others",
				"salesInvoiceNumber": "CRA166SLA166RS6212396",
				"dateOfPurchase": "2022-06-12T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-08-06T18:30:00.000+00:00",
				"tat": 0.04,
				"servifyCallClosureDateTime": "0013-02-12T12:44:00.000+00:00",
				"servifySerialNumber": "CRAK1002005222086",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Not Hot Water",
				"l2": "Hot Switch Not Working",
				"l3": "Hot Switch Replaced $",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			},
			{
				"extReferenceId": "AHD010823FS03575",
				"callType": "FS",
				"productReferenceId": "137621",
				"productName": "CROMA M/W CON 28L CRAM0121",
				"warranty": "Out Of Warranty",
				"productCategory": "Microwave Oven",
				"productSubCategory": "Microwaves Convection",
				"oss": "Others",
				"salesInvoiceNumber": "CRA004SLF02A004110006828",
				"dateOfPurchase": "2012-02-07T18:30:00.000+00:00",
				"pincode": 361001,
				"servifyCallCreationDateTime": "2023-07-31T18:30:00.000+00:00",
				"tat": 1.0,
				"servifyCallClosureDateTime": "0008-02-13T14:08:00.000+00:00",
				"servifySerialNumber": "CRAM012109110001F",
				"technician": "Vikram",
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"l1": "Abnormal Noise",
				"l2": "No Defect Found",
				"l3": "Explained To Customer #",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 0.0,
				"royalty": 0.0,
				"travelAllowance": 0.0,
				"total": 0.0,
				"monthYear": "09/2023",
				"changes": null,
				"comments": null,
				"crAproved": false,
				"crRejected": false,
				"rowColorCode": "#F6F1F1",
				"crraised": false,
				"freezed": false,
				"invoiceGenerated": false
			}
		],
		"cromaUserHierchyList": null,
		"serviceProviders": null,
		"incentivePenaltySummary": [
			{
				"outcome": "Incentive",
				"totalAmount": 1002.99,
				"description": "For 25thsept check",
				"downloadableFiles": [
					{
						"fileName": "shubham_NSOM@croma.com_Sample-Spreadsheet-50000-rows.xls_2023_09_25_18_24_11",
						"link": "aHR0cHM6Ly9zdGFjaWVjb211YXRiaWxscnMwMS5ibG9iLmNvcmUud2luZG93cy5uZXQvaW5jZW50aXZlcGVuYWx0eWRvY3VtZW50cy9zaHViaGFtX05TT00lNDBjcm9tYS5jb21fU2FtcGxlLVNwcmVhZHNoZWV0LTUwMDAwLXJvd3MueGxzXzIwMjNfMDlfMjVfMThfMjRfMTE=",
						"amount": 1002.99
					}
				]
			},
			{
				"outcome": "Penalty",
				"totalAmount": 0.0,
				"description": "Penalty",
				"downloadableFiles": []
			}
		],
		"selectedMonth": "09/2023",
		"isCurrentMonth": true,
		"incentiveDesc": null,
		"penaltyDesc": null,
		"dateTime": "2023/09/25 18:35 PM",
		"responseMsg": null,
		"jobRate": 23595.0,
		"royalty": 580.0,
		"travelAllowance": 0.0,
		"idCSATIncentive": 1080.0,
		"fsCSATIncentive": 300.0,
		"accessoriesBonus": 0.0,
		"totalIncentive": 1002.99,
		"totalPenalty": 0.0,
		"finalAmount": 25397.99,
		"paid": false
	},
	ASPI: {
		"billingSummary": [
			{
				"extReferenceId": "6807060870876086878",
				"callType": "BD",
				"productReferenceId": "5776787",
				"productName": "Washing machine",
				"warranty": "IN",
				"productCategory": "Washing",
				"productSubCategory": "machine",
				"oss": "string",
				"salesInvoiceNumber": "string",
				"dateOfPurchase": "2023-09-12T08:05:20.430Z",
				"pincode": 0,
				"servifyCallCreationDateTime": "2023-09-12T08:05:20.430Z",
				"tat": 0,
				"servifyCallClosureDateTime": "2023-09-12T08:05:20.430Z",
				"servifySerialNumber": "76576589898769897",
				"technician": "string",
				"serviceLocation": "LUCKNOW",
				"l1": "string",
				"l2": "string",
				"l3": "string",
				"repeatInDays": 0,
				"isRepeat": 0,
				"jobRate": 400,
				"royalty": 0,
				"travelAllowance": 50,
				"total": 450,
				"monthYear": "string",
				"changes": "string",
				"comments": "string",
				"crAproved": true,
				"crRejected": true,
				"rowColorCode": "",
				"crraised": true,
				"freezed": true,
				"invoiceGenerated": true
			}
		],
		"cromaUserHierchyList": [
			{
				"userId": "string",
				"userName": "string",
				"userMobileNo": "string",
				"userType": "ASP",
				"serviceProviders": [
					{
						"serviceLocation": "string",
						"aspUserID": "string",
						"aspName": "string",
						"aspMobileNo": "string",
						"vendorCode": "string"
					}
				]
			}
		],
		"serviceProviders": [
			{
				"serviceLocation": "string",
				"aspUserID": "string",
				"aspName": "string",
				"aspMobileNo": "string",
				"vendorCode": "string"
			}
		],
		"incentivePenaltySummary": [
			{
				"outcome": "Incentive",
				"totalAmount": 0,
				"description": "string",
				"downloadableFiles": {
					"additionalProp1": "string",
					"additionalProp2": "string",
					"additionalProp3": "string"
				}
			},
			{
				"outcome": "Penalty",
				"totalAmount": 0,
				"description": "string",
				"downloadableFiles": {
					"additionalProp1": "string",
					"additionalProp2": "string",
					"additionalProp3": "string"
				}
			}
		],
		"selectedMonth": "string",
		"isCurrentMonth": true,
		"incentiveDesc": "string",
		"penaltyDesc": "string",
		"dateTime": "string",
		"responseMsg": "string",
		"jobRate": 5000,
		"royalty": 2000,
		"travelAllowance": 1000,
		"idCSATIncentive": 250,
		"fsCSATIncentive": 250,
		"accessoriesBonus": 250,
		"totalIncentive": 1000,
		"totalPenalty": 1000,
		"finalAmount": 6500,
		"paid": true
	},
	NSOM: {
		"billingSummary": null,
		"cromaUserHierchyList": [
			{
				"userId": "Rakul.Anandhan@croma.com",
				"userName": "Rakul Anandhan",
				"userMobileNo": "6366941595",
				"cromaUserDetails": [
					{
						"userId": "Manojkumar.Patakamoori@Croma.Com",
						"userName": "Manoj Kumar Patakamoori",
						"userMobileNo": "9606682646",
						"cromaUserDetails": null,
						"serviceProviders": [
							{
								"serviceLocation": "S S SERVICEPOINT NALGONDA - 2000008911",
								"aspUserID": "Ssservicep.Nlda.Owner.Cr@Gmail.Com",
								"aspName": "K.Shankar Babu",
								"aspMobileNo": "9000929029",
								"vendorCode": "1000009368"
							},
							{
								"serviceLocation": "RB ENTERPRISES MAHABUBNAGAR - 2000008681",
								"aspUserID": "Rbenterpris.Mbnr.Owner.Cr@Gmail.Com",
								"aspName": "Raju Kumar",
								"aspMobileNo": "6300587220",
								"vendorCode": "1000009307"
							}
						]
					}
				],
				"serviceProviders": null
			}
		],
		"serviceProviders": [],
		"incentivePenaltySummary": null,
		"selectedMonth": "09/2023",
		"isCurrentMonth": true,
		"incentiveDesc": null,
		"penaltyDesc": null,
		"dateTime": "2023/09/12 14:19 PM",
		"responseMsg": null,
		"jobRate": 0.0,
		"royalty": 0.0,
		"travelAllowance": 0.0,
		"idCSATIncentive": 0.0,
		"fsCSATIncentive": 0.0,
		"accessoriesBonus": 0.0,
		"totalIncentive": 0.0,
		"totalPenalty": 0.0,
		"finalAmount": 0.0,
		"paid": false
	},
	RSM: {
		"billingSummary": null,
		"cromaUserHierchyList": [
			{
				"userId": "Manojkumar.Patakamoori@Croma.Com",
				"userName": "Manoj Kumar Patakamoori",
				"userMobileNo": "9606682646",
				"cromaUserDetails": null,
				"userType": "ASI",
				"serviceProviders": [
					{
						"serviceLocation": "S S SERVICEPOINT NALGONDA - 2000008911",
						"aspUserID": "Ssservicep.Nlda.Owner.Cr@Gmail.Com",
						"aspName": "K.Shankar Babu",
						"aspMobileNo": "9000929029",
						"vendorCode": "1000009368"
					}
				]
			}
		],
		"serviceProviders": [],
		"incentivePenaltySummary": null,
		"selectedMonth": "09/2023",
		"isCurrentMonth": true,
		"incentiveDesc": null,
		"penaltyDesc": null,
		"dateTime": "2023/09/12 14:26 PM",
		"responseMsg": null,
		"jobRate": 0.0,
		"royalty": 0.0,
		"travelAllowance": 0.0,
		"idCSATIncentive": 0.0,
		"fsCSATIncentive": 0.0,
		"accessoriesBonus": 0.0,
		"totalIncentive": 0.0,
		"totalPenalty": 0.0,
		"finalAmount": 0.0,
		"paid": false
	},
	ASI: {
		"billingSummary": null,
		"cromaUserHierchyList": [],
		"serviceProviders": [
			{
				"serviceLocation": "SHUBHAM SERVICES NAGPUR - 3000308624",
				"aspUserID": "shubham_ASP@croma.com",
				"aspName": "Shubham ASP",
				"aspMobileNo": "9823123580",
				"vendorCode": "1000009145"
			},
			{
				"serviceLocation": "Vivek SERVICES NAGPUR - 3000308624",
				"aspUserID": "Vivek_ASP@croma.com",
				"aspName": "Vivek ASP",
				"aspMobileNo": "9823123580",
				"vendorCode": "1000009145"
			},
			{
				"serviceLocation": "Komal SERVICES NAGPUR - 3000308624",
				"aspUserID": "Komal_ASP@croma.com",
				"aspName": "Komal ASP",
				"aspMobileNo": "9823123580",
				"vendorCode": "1000009145"
			},
			{
				"serviceLocation": "Payal SERVICES NAGPUR - 3000308624",
				"aspUserID": "Payal_ASP@croma.com",
				"aspName": "Payal ASP",
				"aspMobileNo": "9823123580",
				"vendorCode": "1000009145"
			},
			{
				"serviceLocation": "Arun SERVICES NAGPUR - 3000308624",
				"aspUserID": "Arun_ASP@croma.com",
				"aspName": "Arun ASP",
				"aspMobileNo": "9823123580",
				"vendorCode": "1000009145"
			}
		],
		"incentivePenaltySummary": null,
		"selectedMonth": "09/2023",
		"isCurrentMonth": true,
		"incentiveDesc": null,
		"penaltyDesc": null,
		"dateTime": "2023/09/21 18:19 PM",
		"responseMsg": null,
		"jobRate": 0.0,
		"royalty": 0.0,
		"travelAllowance": 0.0,
		"idCSATIncentive": 0.0,
		"fsCSATIncentive": 0.0,
		"accessoriesBonus": 0.0,
		"totalIncentive": 0.0,
		"totalPenalty": 0.0,
		"finalAmount": 0.0,
		"paid": false
	}
}

export const dummyBillingChangeRequestData = {
	"billingChangeRequests": [
		{
			"serviceLocation": "CREDENCE SERVICES KOLKATA - 2000007207",
			"aspUserId": "Suman Nathe",
			"aspContactNo": "9874185197",
			"pendingCRs": 5,
			"billingSummaryCR": [
				{
					"createdBy": null,
					"createdDate": null,
					"lastModifiedBy": "kunal.singh.tcs@cromaretail.com",
					"lastModifiedDate": "2023-06-26T16:51:22",
					"id": "SFC011122ID30455",
					"extReferenceId": null,
					"deliveryType": "Installation",
					"productId": 242597,
					"productName": "CROMA LED HDR 32inch EL7369 1Yr Wrty",
					"brand": "Croma",
					"warranty": "Warranty",
					"productCategory": "Television",
					"dateOfPurchase": "2022-10-28T00:00:00.000+00:00",
					"pincode": 700009,
					"tat": 0.76,
					"servifySerialNumber": "CREL7369092204432",
					"solutionProvided": "Demo/Installation Of New Product #",
					"repeatInDays": 0,
					"subCategory": "LED =32\"",
					"closureTAT": 0.76,
					"royalty": 12,
					"callRate": 300.0,
					"repeatCallRate": null,
					"callRateCategory": "< 24 Hours",
					"bdIncentivePayout": 0,
					"idIncentivePayout": 90,
					"incentive": 0.0,
					"penalty": 0.0,
					"travelAllowance": null,
					"totalPayout": 390.0,
					"changes": "true",
					"comments": "Kunal made fjfjghj jghjgjghjghjghjghjghj jhghjghjgjgjgghjgh hjhjghjgjgjgjg ",
					"serviceLocation": "CREDENCE SERVICES KOLKATA - 2000007207",
					"monthYear": "05/2023",
					"aspUserId": null,
					"isRSMApprovalRequred": null,
					"commentByASI": null,
					"commentByRSM": null,
					"paid": false,
					"freezed": false,
					"crAprovedByASI": false,
					"crRejectedbyASI": false,
					"crraised": false,
					"crAprovedByRSM": false,
					"crRejectedbyRSM": false,
					"invoiceGenerated": false
				}
			]
		}
	],
	"totalCRno": 10,
	"monthYear": "07/2023"
}