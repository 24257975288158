import { createContext, useContext, useReducer } from 'react';
import { appReducer, initialStates } from './reducer';

const AppContext = createContext(null);

const AppDispatchContext = createContext(null);

export function AppProvider({ children }) {
  const [store, dispatch] = useReducer(
    appReducer,
    initialStates
  );

  return (
    <AppContext.Provider value={store}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

export function useReduxState() {
  return useContext(AppContext);
}

export function useDispatch() {
  return useContext(AppDispatchContext);
}


