import { getCurrentDate } from "../utils/utils";


export function appReducer(state = initialStates, action) {
  switch (action.type) {
    case 'ROW_DATA': {
      return { ...state, isModalOpen: true, rowData: action.payload };
    }
    case 'MODAL_CLOSE': {
      return { ...state, isModalOpen: false };
    }
    case 'SET_BILLING_DATE': {
      return { ...state, selectedBillingDate: action.payload };
    }
    case 'CHANGE_REQUEST': {
      return { ...state, changeRequestData: action.payload };
    }
    case 'CHANGE_REQUEST_RESPONSE': {
      return { ...state, changeRequestDataResponse: action.payload };
    }
    case 'CHANGE_REQUEST_CLOSE': {
      return { ...state, changeRequestData: null, changeRequestDataResponse: {} };
    }
    case 'SET_USER': {
      return { ...state, userDetails: action.payload };
    }
    case 'GET_TOKEN': {
      return { ...state, isToken: action.payload };
    }
    case 'APPROVAL_PENDING_DATA': {
      return { ...state, approvalPendingData: action.payload };
    }
    case 'BILLING_SUMMARY_DATA': {
      return { ...state, billingSummaryData: action.payload };
    }
    case 'AXIOS_PARAMS': {
      return { ...state, axiosParams: action.payload };
    }
    case 'SESSION_EXPIRED': {
      return { ...state, sessionExpired: true };
    }
    case 'SCREEN_PADDING': {
      return { ...state, isPadding: action.payload };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const initialStates = {
  isModalOpen: false,
  rowData: null,
  selectedBillingDate: getCurrentDate(),
  changeRequestData: null,
  changeRequestDataResponse: {},
  isToken: '',
  userDetails: {},
  approvalPendingData: {},
  billingSummaryData: {},
  axiosParams: null,
  sessionExpired: false,
  isPadding: false
}