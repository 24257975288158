export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: `${process.env.REACT_APP_BASE_URL}/home`,
    postLogoutRedirectUri: `${process.env.REACT_APP_BASE_URL}/login`,
    navigateToLoginRequestUrl: true,
    onlySignOutFromRedirectUri: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {},
      logLevel: "Verbose",
    },
  },
};