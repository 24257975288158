import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    primary: {
      light: "#FFF",
      main: "#2E2E2E", //aqua
      text: "#000", //white
    },
    secondary: {
      light: "#FFFF00", //yellow
      main: "#008000", //green
      text: "#fff", //white
    },
    custom: {
      light: "#EDEDED", //aqua
      main: "#EDEDED", //croma
      dark: "#EDEDED", //orange
      text: "#000", //white
      danger: "red", //red
    },
    action: {
      disabledBackground: "#EDEDED",
      disabled: "#000",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});