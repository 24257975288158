// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/RobotoMono-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --navbar-carousel-color: #353535;
  --text-color: #121212;
  --croma-wall: #ffffff;
  --old-price: #9a9a9a;
  --foot-color: #1d1d1d;
  --hyperlink: #00E9BF;
  --circle: #808080;
  --input-color: #333;
  --neon-green: #00e9bf;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

body {
  margin: 0;
  background-color: var(--croma-wall) !important;
  color: var(--text-color);
  font-size: 1.2rem;
  font-family: 'Roboto Mono';
  overflow: hidden;
}

* {
  font-family: 'Roboto Mono' !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,qBAAqB;EACrB,qBAAqB;EACrB,oBAAoB;EACpB,qBAAqB;EACrB,oBAAoB;EACpB,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,4CAA0C;AAC5C;;AAEA;EACE,SAAS;EACT,8CAA8C;EAC9C,wBAAwB;EACxB,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[":root {\n  --navbar-carousel-color: #353535;\n  --text-color: #121212;\n  --croma-wall: #ffffff;\n  --old-price: #9a9a9a;\n  --foot-color: #1d1d1d;\n  --hyperlink: #00E9BF;\n  --circle: #808080;\n  --input-color: #333;\n  --neon-green: #00e9bf;\n}\n\n@font-face {\n  font-family: 'Roboto Mono';\n  src: url(../public/RobotoMono-Regular.ttf);\n}\n\nbody {\n  margin: 0;\n  background-color: var(--croma-wall) !important;\n  color: var(--text-color);\n  font-size: 1.2rem;\n  font-family: 'Roboto Mono';\n  overflow: hidden;\n}\n\n* {\n  font-family: 'Roboto Mono' !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
