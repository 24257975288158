import axios from "axios";
import CryptoJS from "crypto-js";
import * as Constant from "./constants";
import { BroadcastChannel } from 'broadcast-channel';

const logoutChannel = new BroadcastChannel('logout');
export const setAccessToken = (user) => {
  axios
    .post(
      Constant.authUrl,
      {
        username: user,
        password: Constant.authPassword, //same for all user for now
      },
      {
        headers: {
          "Content-Type": "application/json",
          "traceId": getTraceID()
        },
      }
    )
    .then((response) => {
      sessionStorage.setItem("accessToken", response.data.token);
      localStorage.setItem("token", response.data.token);
      sessionStorage.setItem("userId", user);
    })
    .catch((error) => {
      console.log("error", error);
    });
}

export const getAuthorizedUser = async () => {
  let payload = { userId: sessionStorage.getItem("userId") }
  try {
    let response = await axios
      .post(
        Constant.autherizeUrl, payload,
        {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
            "traceId": getTraceID()
          },
        }
      )
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTraceID = () => {
  const date = new Date();
  return Math.floor((Math.random() * 1000000)) + ':' + date.getDate().toString() + date.getMonth().toString() + date.getFullYear().toString() + date.getHours().toString() + date.getMinutes().toString() + date.getSeconds().toString();
}


export const getMaskedJsonReqString = (data) => {
  const secretPass = CryptoJS.enc.Latin1.parse(Constant.ENCRYPTION_KEY);
  let iv = CryptoJS.enc.Latin1.parse(Constant.ENCRYPTION_IV);
  // console.log("token", secretPass);
  // console.log("dataa", JSON.stringify(data));

  const encryptdata = CryptoJS.AES.encrypt(JSON.stringify(data), secretPass, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString();
  // console.log("encrypt", encryptdata);
  return encryptdata;
}

export const getDeMaskedJsonResString = (data) => {
  const secretPass = CryptoJS.enc.Latin1.parse(Constant.ENCRYPTION_KEY);;
  let iv = CryptoJS.enc.Latin1.parse(Constant.ENCRYPTION_IV);
  // console.log("token", secretPass);
  const decryptdata = CryptoJS.AES.decrypt(data, secretPass, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    // console.log("decrypt", decryptdata.toString(CryptoJS.enc.Utf8));
  return decryptdata.toString(CryptoJS.enc.Utf8);
}

export const logout = () => {
  axios
    .get(Constant.logoutURL,
      {
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
          "traceId": getTraceID()
        },
      }
    )
    .then((response) => {
      console.log(response)
      logoutChannel.postMessage("Logout");
      localStorage.removeItem("token");
      sessionStorage.clear();
    })
    .catch((error) => {
      console.log("error", error);
      logoutChannel.postMessage("Logout");
      localStorage.removeItem("token");
      sessionStorage.clear();
    });
}

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  }
}

export const getFormattedDate = (date, formatType) => {
  if (date && formatType === 'dd/mm/yyyy' && date?.includes('T')) {
    return new Date(date.split('T')[0]).toLocaleDateString('en-GB')
  }
  if (date && formatType === 'yyyy-dd-mm' && date?.includes('T')) {
    return new Date(date.split('T')[0]).toISOString().split('T')[0]
  }
  return date;
}

export const saveFileAs = (contentAsBlob, fileName) => {
  const a = document.createElement("a");
  let url = contentAsBlob
  url = window.URL.createObjectURL(contentAsBlob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const getUpdatedModalData = (dataArr, rowData) => {
  for (let obj of dataArr) {
    if (!obj.value)
      obj.value = rowData[obj.key]
  }
  return dataArr;
}

export const getColorStyle = (status) => {
  switch (status) {
    case 'Payment Pending':
      return '#F48E30';
    case 'Ongoing':
      return '#249F10';
    default:
      break;
  }
}

export const getDateAndNullFormat = (dataObj, dateFormat) => {
  for (const key in dataObj) {
    if (Object.hasOwnProperty.call(dataObj, key)) {
      const value = dataObj[key];
      if (value === null) {
        dataObj[key] = '-'
      }
      if (key?.toLowerCase()?.includes('date') && value !== null) {
        dataObj[key] = getFormattedDate(value, dateFormat)
      }
    }
  }
  return dataObj;
}

export const getFormattedRowsForBillingSummary = (billingSummary) => {
  let finalArr = []
  for (let index = 0; index < billingSummary?.length; index++) {
    const element = billingSummary[index];
    let duplicateArr = finalArr.filter((e) => e.date === getFormattedDate(element?.servifyCallClosureDateTime, 'dd/mm/yyyy'))
    if (duplicateArr.length) {
      for (let index = 0; index < finalArr.length; index++) {
        const finalEle = finalArr[index];
        if (finalEle.date === getFormattedDate(element.servifyCallClosureDateTime, 'dd/mm/yyyy')) {
          finalEle.billing.push(getDateAndNullFormat(element, 'yyyy-dd-mm'))
        }
      }
    }
    if (element?.servifyCallClosureDateTime && !duplicateArr.length) {
      finalArr.push({ date: getFormattedDate(element?.servifyCallClosureDateTime, 'dd/mm/yyyy'), billing: [getDateAndNullFormat(element, 'yyyy-dd-mm')] })
    }
  }
  finalArr = sortObjArrDatewise(finalArr);
  return finalArr;
}

export const sortObjArrDatewise = (dataArray) => {
  dataArray.sort(function (a, b) {
    var aa = a?.date?.split('/')?.reverse()?.join(),
      bb = b?.date?.split('/')?.reverse()?.join();
    return aa < bb ? 1 : (aa > bb ? -1 : 0);
  });
  return dataArray;
}

export const callUploadIncentive = async (multiPart, ASP, page, fileName) => {
  const formData = new FormData();
  formData.append("file", multiPart, fileName);
  let url = Constant.uploadIncentiveUrl + `?type=${page}&aspUserId=${ASP}`
  try {
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "multipart/form-data",
          "traceId": getTraceID()
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const callAddIncentivePenalty = async (ASP, page, amount, reason) => {
  let payload = {
    aspUserId: ASP,
    type: page,
    amount: amount,
    title: reason,
  }

  try {
    const response = await axios.post(
      Constant.addIncentivePenaltyUrl,
      payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          "traceId": getTraceID()
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export const currentMonth = () => {
  let date = new Date();
  let mon = date.getMonth();
  let year = date.getFullYear();
  mon++;
  if (mon < 10) {
    mon = '0' + mon;
  }
  return mon + '/' + year;
}

export const optimizedRowObject = (dataObj) => {
  // this function coverts "-" to "null" in an object
  let updatedRow = Object.assign({}, dataObj);
  for (const key in updatedRow) {
    if (updatedRow[key] === "-") {
      updatedRow[key] = null;
    }
  }
  return updatedRow;
}

export const getChangeReqProps = (resObject) => {
  let toReturn = null;
  if (!!resObject?.response) {
    toReturn = { type: 'success', value: resObject?.response?.data?.responseMsg }
  } else if (!!resObject.error) {
    toReturn = { type: 'error', value: resObject?.error?.response?.data?.errorMsg || resObject?.error?.response?.data?.message }
  }
  return toReturn;
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const callUploadTA = async (blob, page) => {
  const formData = new FormData();
  formData.append("file", blob);
  let url = page === 'TA' ? Constant.uploadTAUrl : Constant.uploadABUrl
  try {
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          "Content-Type": "multipart/form-data",
          "traceId": getTraceID()
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error
  }
}

export const getCurrentDate = () => {
  let currentMonth = (new Date()?.getMonth() + 1)?.toString();
  currentMonth = currentMonth?.length > 1 ? currentMonth : `0${currentMonth}`;
  let currentYear = new Date()?.getFullYear()?.toString();
  return `${currentMonth}/${currentYear}`;
}

export const checkFileSize = (fileSizeInBytes, limit, unit) => {
  let unitArray = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = unitArray.findIndex((e) => e === unit);
  let convertedSize = Number(limit) * Math.pow(1024, unitIndex)
  return convertedSize <= fileSizeInBytes;
}

export const checkFileExtensionType = (fileName, fileType) => {
  const fileExtension = fileName?.substring(fileName.lastIndexOf('.') + 1, fileName.length);
  return fileExtension?.toLowerCase() === fileType?.toLowerCase();
}

export const finaliseBillingSummary = async () => {
  let payload = { userId: sessionStorage.getItem("userId"), monthYear: getCurrentDate() }
  try {
    let response = await axios
      .post(
        Constant.finaliseBillingSummaryUrl,
        payload,
        {
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
            "traceId": getTraceID()
          },
        }
      )
    return response;
  } catch (error) {
    throw error;
  }
}