import React, { useEffect, useState, lazy } from 'react'
import { useDispatch, useReduxState } from './redux/context';
import { getAuthorizedUser, getDeMaskedJsonResString, logoutAllTabs } from "./utils/utils";
import "./App.css";
const FetchURL = lazy(() => import("./components/FetchURL/FetchURL"));
const PageLayout = lazy(() => import("./components/PageLayout/PageLayout"));
const BackdropLoader = lazy(() => import("./components/BackdropLoader/BackdropLoader"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));


const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const dispatch = useDispatch();
  const state = useReduxState();
  const { userDetails: user } = state;

  // useEffect(() => {
  //   logoutAllTabs()
  // }, [])

  useEffect(() => {
    //dispatch({ type: "SET_USER", payload: { designation: 'ASP' } });
    setLoading(true);
    getAuthorizedUser()
      .then((response) => {
        setLoading(false);
        let userDetails = JSON.parse(getDeMaskedJsonResString(response?.data.maskedJsonRespString))
        if(userDetails.userId === sessionStorage.getItem("userId"))
        dispatch({ type: "SET_USER", payload: JSON.parse(getDeMaskedJsonResString(response?.data.maskedJsonRespString)) });
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        setErrorMessage(err?.response?.data?.message || 'Something went wrong!');
        dispatch({ type: "SET_USER", payload: null });
        if (err?.response?.data?.statusCode === 401) {
          dispatch({ type: 'SESSION_EXPIRED' });
        }
      })
      handleExpire();
  }, [])

  const handleExpire = () => {
    setTimeout(() => {
      let time = sessionStorage.getItem('reqhead');
      let dt1 = new Date()
      let dt2 = new Date(time);
      console.log(dt2.getTime(),dt1.getTime());
      if(dt2.getTime()<dt1.getTime())
        dispatch({ type: 'SESSION_EXPIRED' });
      handleExpire();
    }, "300000");
    
  }

  return (
    <PageLayout user={user}>
      <div className={state?.isPadding ? 'customContainer' : 'mainContainer'} >
        {user?.designation && !loading ? <FetchURL /> : !user && !loading ? < PageNotFound user={user} errorMessage={errorMessage} />
          : <BackdropLoader loading={loading} />}
      </div>
    </PageLayout>
  );
};

export default App;
