import React, { lazy } from 'react'
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { ThemeProvider } from "@mui/material/styles";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { customTheme } from "./CustomTheme";
import { AppProvider } from './redux/context';
const AuthLogin = lazy(() => import('./components/AuthLogin/AuthLogin'));

const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
    sessionStorage.setItem('accessToken', event?.payload?.accessToken);
    sessionStorage.setItem('userId', pca?.getActiveAccount()?.username?.toLowerCase());
    sessionStorage.setItem('reqhead', event?.payload?.expiresOn );
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  {window.self === window.top &&
    <MsalProvider instance={pca}>
      <ThemeProvider theme={customTheme}>
        <AuthenticatedTemplate>
          <AppProvider>
            <App pca={pca} />
          </AppProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AuthLogin />
        </UnauthenticatedTemplate>
      </ThemeProvider>
    </MsalProvider>}
  </BrowserRouter >
);
reportWebVitals();
